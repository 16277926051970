import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../lib/firebase';

const validateImage = (file: File) => {
  // Check file type
  if (!file.type.startsWith('image/')) {
    throw new Error('File must be an image');
  }
  
  // Check file size (5MB)
  if (file.size > 5 * 1024 * 1024) {
    throw new Error('Image must be less than 5MB');
  }
};

export const uploadImage = async (file: File, path: string): Promise<string> => {
  try {
    validateImage(file);
    
    // Create a storage reference
    const storageRef = ref(storage, path);
    
    // Upload the file with content type
    const metadata = {
      contentType: file.type,
    };
    
    const snapshot = await uploadBytes(storageRef, file, metadata);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to upload image: ${error.message}`);
    }
    throw new Error('Failed to upload image');
  }
};

export const uploadVenueImage = async (
  venueId: string, 
  file: File, 
  type: 'profile' | 'gallery'
): Promise<string> => {
  if (!venueId) {
    throw new Error('Venue ID is required');
  }

  // Create a unique filename with timestamp and original extension
  const extension = file.name.split('.').pop();
  const fileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${extension}`;
  const path = `venues/${venueId}/${type}/${fileName}`;
  
  // Upload the image and get the URL
  return await uploadImage(file, path);
};