// src/components/Layout.tsx
import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Menu, UserCircle } from 'lucide-react';
import { ProfileMenu } from './modals/ProfileMenu';
import { Footer } from './Footer';
import { DarkModeToggle } from './DarkModeToggle';
import { LanguageSwitcher } from './LanguageSwitcher';
import { AuthPromptModal } from './modals/AuthPromptModal';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';

export const Layout: React.FC = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { t } = useLanguage();
  const isVendor = location.pathname.startsWith('/vendor');

  const handleProfileClick = () => {
    if (user) {
      setIsProfileOpen(true);
    } else {
      setShowAuthPrompt(true);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
      setIsProfileOpen(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-dark-900 transition-colors duration-200">
      <nav className="sticky top-0 z-50 bg-white dark:bg-dark-800 border-b border-gray-200 dark:border-dark-700">
        <div className="w-full max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center">
                <span className="text-xl sm:text-2xl font-black tracking-wider text-gray-900 dark:text-white rounded-lg">
                  BIDCOURT
                </span>
              </Link>
            </div>

            {/* Right side navigation */}
            <div className="flex items-center space-x-2 sm:space-x-4">
              {/* My Bids Link - Only show when user is logged in and not on vendor pages */}
              {user && !isVendor && (
                <Link
                  to="/my-bids"
                  className={`hidden sm:inline-flex items-center px-3 py-1 text-sm font-medium ${
                    location.pathname === '/my-bids'
                      ? 'text-gray-900 dark:text-white'
                      : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                  }`}
                >
                  {t('nav.myBids')}
                </Link>
              )}
              
              <DarkModeToggle />
              <LanguageSwitcher />
              <button
                onClick={handleProfileClick}
                className="p-1 sm:p-2 rounded-full text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              >
                <UserCircle className="h-5 w-5 sm:h-6 sm:w-6" />
              </button>
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="sm:hidden p-1 rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              >
                <Menu className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`sm:hidden transition-all duration-200 ease-in-out ${
            isMobileMenuOpen ? 'max-h-64' : 'max-h-0'
          } overflow-hidden`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            {user && !isVendor ? (
              <Link
                to="/my-bids"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {t('nav.myBids')}
              </Link>
            ) : (
              <>
                <Link
                  to="/login"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('auth.signIn')}
                </Link>
                <Link
                  to="/signup"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('auth.createAccount')}
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>

      <main className="flex-1 bg-gray-50 dark:bg-dark-900 w-full">
        <Outlet />
      </main>

      <Footer />

      {isProfileOpen && user && (
        <ProfileMenu onClose={() => setIsProfileOpen(false)} onLogout={handleLogout} />
      )}

      {showAuthPrompt && !user && (
        <AuthPromptModal onClose={() => setShowAuthPrompt(false)} />
      )}
    </div>
  );
};
