// src/pages/AuctionDetail.tsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, Clock, Users } from 'lucide-react';
import { format, parse, parseISO } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { BidModal } from '../components/modals/BidModal';
import { AuthPromptModal } from '../components/modals/AuthPromptModal';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Countdown } from '../components/Countdown';

export const AuctionDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [auction, setAuction] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const { user } = useAuth();
  const { t } = useLanguage();

  useEffect(() => {
    const fetchAuction = async () => {
      if (!id) return;
      
      try {
        const auctionDoc = await getDoc(doc(db, 'auctions', id));
        if (auctionDoc.exists()) {
          const data = auctionDoc.data();
          
          // Fetch venue data
          const venueDoc = await getDoc(doc(db, 'venues', data.venueId));
          const venueData = venueDoc.data();
          
          // Fetch court data
          const courtDoc = await getDoc(doc(db, 'courts', data.courtId));
          const courtData = courtDoc.data();

          setAuction({
            id: auctionDoc.id,
            ...data,
            court: {
              id: data.courtId,
              name: courtData?.name || 'Unnamed Court',
              type: courtData?.type || 'Unknown Type',
              type2: courtData?.type2 || 'Outdoor',
              venue: {
                id: data.venueId,
                name: venueData?.name || 'Unknown Venue',
                profileImage: venueData?.profileImage,
              },
            },
          });
        }
      } catch (error) {
        console.error('Error fetching auction:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuction();
  }, [id]);

  const handleBidClick = () => {
    if (!user) {
      setShowAuthPrompt(true);
    } else {
      setShowBidModal(true);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (!auction) return <div>Auction not found</div>;

  try {
    const eventStartTime = parse(
      `${auction.date} ${auction.startTime}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );

    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <nav className="mb-8">
          <Link
            to="/"
            className="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
          >
            ← {t('common.back')}
          </Link>
        </nav>

        <div className="bg-white dark:bg-dark-800 shadow-sm rounded-lg overflow-hidden">
          {/* Court Image */}
          <div className="w-full h-64 bg-gray-200 dark:bg-dark-700">
            {auction.court?.venue?.profileImage ? (
              <img
                src={auction.court.venue.profileImage}
                alt={auction.court.venue.name}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <span className="text-gray-400">{t('common.noImage')}</span>
              </div>
            )}
          </div>

          <div className="p-4 sm:p-6">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                  {auction.court?.name}
                </h1>
                <Link
                  to={`/center/${auction.court?.venue?.id}`}
                  className="text-base sm:text-lg text-gray-600 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400"
                >
                  {auction.court?.venue?.name}
                </Link>
              </div>
              <div className="flex gap-2 flex-col">
                <span className="inline-flex items-center justify-center px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm font-semibold bg-indigo-100 dark:bg-indigo-900/50 text-indigo-800 dark:text-indigo-300">
                  {auction.court?.type}
                </span>
                <span className="inline-flex items-center justify-center px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm font-semibold bg-white dark:bg-white text-gray-700 dark:text-gray-700">
                  {auction.court?.type2}
                </span>
              </div>
            </div>

            {/* Date and Time - Now in a vertical layout */}
            <div className="flex flex-col space-y-2 mt-3 sm:mt-4">
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                <Calendar className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2" />
                {format(eventStartTime, 'MMMM d, yyyy')}
              </div>
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                <Clock className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2" />
                {format(parse(auction.startTime, 'HH:mm', new Date()), 'HH:mm')} - {' '}
                {format(parse(auction.endTime, 'HH:mm', new Date()), 'HH:mm')}
              </div>
            </div>

            <div className="mt-6 sm:mt-8 bg-white dark:bg-dark-800 rounded-lg p-4 sm:p-6 shadow-sm">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                    {t('bid.currentBid')}
                  </p>
                  <p className="text-2xl sm:text-3xl font-bold text-indigo-600 dark:text-indigo-400">
                    €{auction.currentBid}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                    <Users className="h-3.5 w-3.5 sm:h-4 sm:w-4 inline mr-1" />
                    {auction.participants || 0} {t('bid.participants')}
                  </p>
                  <div className="text-xs sm:text-sm font-medium">
                    <Clock className="h-3.5 w-3.5 sm:h-4 sm:w-4 inline mr-1 text-orange-500" />
                    <Countdown endTime={new Date(auction.auctionEndTime)} />
                  </div>
                </div>
              </div>

              <button
                onClick={handleBidClick}
                className="mt-3 sm:mt-4 w-full bg-indigo-600 text-white py-2.5 sm:py-3 px-4 rounded-md hover:bg-indigo-700 transition-colors text-sm sm:text-base"
              >
                {t('bid.placeBid')}
              </button>
            </div>
          </div>
        </div>

        {showBidModal && (
          <BidModal
            auction={{
              id: auction.id,
              courtName: auction.court?.name || '',
              venueName: auction.court?.venue?.name || '',
              currentBid: auction.currentBid,
              basePrice: auction.basePrice,
              date: format(parseISO(auction.date), 'dd/MM/yyyy'),
              startTime: auction.startTime,
              endTime: auction.endTime,
              image: auction.court?.venue?.profileImage
            }}
            onClose={() => setShowBidModal(false)}
          />
        )}

        {showAuthPrompt && (
          <AuthPromptModal onClose={() => setShowAuthPrompt(false)} />
        )}
      </div>
    );
  } catch (error) {
    console.error('Error rendering auction detail:', error);
    return <div>Error loading auction details</div>;
  }
};
