import React, { useState } from 'react';
import { AlertTriangle, Bell, CheckCircle, XCircle, Clock } from 'lucide-react';

export const SystemAlerts: React.FC = () => {
  const [filter, setFilter] = useState('all');

  // Mock data - replace with actual API call
  const alerts = [
    {
      id: 1,
      type: 'error',
      message: 'Payment processing failed for auction #1234',
      timestamp: new Date(),
      status: 'open'
    },
    {
      id: 2,
      type: 'warning',
      message: 'High server load detected',
      timestamp: new Date(Date.now() - 3600000),
      status: 'resolved'
    },
    {
      id: 3,
      type: 'info',
      message: 'New venue registration pending approval',
      timestamp: new Date(Date.now() - 7200000),
      status: 'open'
    }
  ];

  const getAlertIcon = (type: string) => {
    switch (type) {
      case 'error':
        return <XCircle className="h-5 w-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
      case 'info':
        return <Bell className="h-5 w-5 text-blue-500" />;
      default:
        return <Bell className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">System Alerts</h2>
        
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
        >
          <option value="all">All Alerts</option>
          <option value="open">Open</option>
          <option value="resolved">Resolved</option>
        </select>
      </div>

      <div className="bg-white dark:bg-dark-800 shadow overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200 dark:divide-dark-700">
          {alerts.map((alert) => (
            <div key={alert.id} className="p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  {getAlertIcon(alert.type)}
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {alert.message}
                    </p>
                    <div className="flex items-center mt-1 space-x-2">
                      <Clock className="h-4 w-4 text-gray-400" />
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {alert.timestamp.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    alert.status === 'open'
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-400'
                      : 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-400'
                  }`}>
                    {alert.status}
                  </span>
                  {alert.status === 'open' && (
                    <button className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-900 dark:hover:text-indigo-300">
                      Resolve
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};