import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';
import type { VenueData } from '../types';

export const useVenueData = () => {
  const [venueData, setVenueData] = useState<VenueData | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.id) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'venues', user.id),
      (doc) => {
        if (doc.exists()) {
          setVenueData(doc.data() as VenueData);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching venue data:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user?.id]);

  return { venueData, loading };
}; 