// src/components/admin/LiveAuctions.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Clock, Calendar, Building2, Users } from 'lucide-react';
import { format, parse } from 'date-fns';
import { useAdminAuctions } from '../../hooks/useAdminAuctions';
import { LoadingSpinner } from '../LoadingSpinner';
import { Search } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { Countdown } from '../Countdown';

export const LiveAuctions: React.FC = () => {
  const { t } = useLanguage();
  const [searchQuery, setSearchQuery] = useState('');
  const { auctions, loading, error } = useAdminAuctions();
  const navigate = useNavigate();
  const [selectedAuction, setSelectedAuction] = useState<any>(null);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">{error}</div>;

  const filteredAuctions = auctions?.filter(auction => {
    const searchText = `${auction.court?.name} ${auction.court?.venue?.name}`.toLowerCase();
    return searchText.includes(searchQuery.toLowerCase());
  });

  const formatAuctionTime = (auction: any) => {
    try {
      if (auction.startTime?.toDate) {
        return format(auction.startTime.toDate(), 'h:mm a');
      }
      
      if (typeof auction.startTime === 'string') {
        const [hours, minutes] = auction.startTime.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        return format(date, 'h:mm a');
      }

      if (auction.startTime instanceof Date) {
        return format(auction.startTime, 'h:mm a');
      }

      return 'Invalid time';
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Invalid time';
    }
  };

  return (
    <div className="space-y-6">
      {/* Search Bar */}
      <div className="relative">
        <input
          type="text"
          placeholder={t('search.searchByCourtOrVenue')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 pl-10 bg-white dark:bg-dark-800 border border-gray-300 dark:border-dark-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      {/* Auctions Grid */}
      {filteredAuctions && filteredAuctions.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredAuctions.map((auction) => (
            <div key={auction.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
              {/* Profile Image Section */}
              <div className="w-full h-48 relative">
                <Link to={`/auction/${auction.id}`}>
                  {auction?.court?.venue?.profileImage ? (
                    <img
                      src={auction.court.venue.profileImage}
                      alt={auction.court.venue.name || 'Venue'}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-100 dark:bg-dark-700 flex items-center justify-center">
                      <Building2 className="h-12 w-12 text-gray-400" />
                    </div>
                  )}
                </Link>
                {/* Badges */}
                <div className="absolute top-3 right-3 flex flex-col gap-2">
                  <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-indigo-100 text-indigo-800 dark:bg-indigo-900/50 dark:text-indigo-300">
                    {auction.court?.type}
                  </span>
                  <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-white text-gray-700 shadow-sm">
                    {auction.court?.type2}
                  </span>
                </div>
              </div>

              {/* Auction Info */}
              <div className="p-4">
                <div className="mb-3">
                  <Link 
                    to={`/auction/${auction.id}`}
                    className="text-lg font-medium text-gray-900 dark:text-white hover:text-indigo-600 transition-colors"
                  >
                    {auction.court?.name || 'Unnamed Court'}
                  </Link>
                  <Link 
                    to={`/center/${auction.court?.venue?.id}`}
                    className="block mt-1 text-sm text-gray-600 dark:text-gray-400 hover:text-indigo-600 transition-colors"
                  >
                    {auction.court?.venue?.name || 'Unknown Venue'}
                  </Link>
                </div>

                <div className="flex justify-between items-start mb-3">
                  <div>
                    <p className="text-xs text-gray-600 dark:text-gray-400 mb-1">{t('bid.currentBid')}</p>
                    <p className="text-xl font-bold text-indigo-600">
                      €{auction.currentBid || auction.basePrice}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <Users className="h-4 w-4 text-gray-500 dark:text-gray-400 mr-1" />
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {auction.participants || 0}
                    </span>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                    <Calendar className="h-4 w-4 mr-1" />
                    {auction.date}
                  </div>
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                    <Clock className="h-4 w-4 mr-1" />
                    {formatAuctionTime(auction)} - {formatAuctionTime({ ...auction, startTime: auction.endTime })}
                  </div>
                  <div className="flex items-center">
                    <Clock className="h-4 w-4 mr-1 text-orange-500" />
                    <Countdown endTime={new Date(auction.auctionEndTime)} />
                  </div>
                </div>

                <button
                  onClick={() => navigate(`/auction/${auction.id}`)}
                  className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors"
                >
                  {t('vendor.viewDetails')}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white dark:bg-dark-800 rounded-lg">
          <p className="text-gray-500 dark:text-gray-400">
            {searchQuery 
              ? t('auctions.noAuctionsFound') 
              : t('auctions.noActiveAuctions')}
          </p>
        </div>
      )}
    </div>
  );
};
