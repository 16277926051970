import React, { useEffect, useState } from 'react';
import { Building2 } from 'lucide-react';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { LoadingSpinner } from '../LoadingSpinner';

interface Venue {
  id: string;
  name: string;
  address: string;
  city: string;
  status: 'pending' | 'active';
  courtsCount: number;
  revenue: number;
  ownerId?: string;
}

export const VenuesList: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const venuesQuery = query(collection(db, 'venues'));
        const venuesSnapshot = await getDocs(venuesQuery);
        
        // Fetch all venues and their corresponding user documents
        const venuesData = await Promise.all(
          venuesSnapshot.docs.map(async (venueDoc) => {
            const venueData = venueDoc.data() as Venue;
            const venueId = venueDoc.id;
            
            // Only try to fetch user data if ownerId exists
            let status: 'pending' | 'active' = 'pending';
            if (venueData.ownerId) {
              try {
                const userDoc = await getDoc(doc(db, 'users', venueData.ownerId));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  status = userData?.hasChangedPassword ? 'active' : 'pending';
                }
              } catch (err) {
                console.error(`Error fetching user data for venue ${venueId}:`, err);
              }
            }
            
            return {
              ...venueData,
              id: venueId,
              status,
              courtsCount: venueData.courtsCount || 0,
              revenue: venueData.revenue || 0
            };
          })
        );

        setVenues(venuesData);
      } catch (err) {
        console.error('Error fetching venues:', err);
        setError('Failed to load venues');
      } finally {
        setLoading(false);
      }
    };

    fetchVenues();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 dark:border-dark-700 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-700">
              <thead className="bg-gray-50 dark:bg-dark-700">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Venue
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Courts
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Revenue
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-dark-800 divide-y divide-gray-200 dark:divide-dark-700">
                {venues.map((venue) => (
                  <tr key={venue.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-dark-700 flex items-center justify-center">
                            <Building2 className="h-6 w-6 text-gray-400" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900 dark:text-white">
                            {venue.name}
                          </div>
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {venue.address}, {venue.city}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        venue.status === 'active'
                          ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-400'
                          : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-400'
                      }`}>
                        {venue.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {venue.courtsCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      ${venue.revenue.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};