import { httpsCallable } from 'firebase/functions';
import { functions } from '../lib/firebase';

interface EmailData {
  to: string;
  templateAlias: string;
  templateData: Record<string, any>;
}

export const emailService = {
  async sendUserWelcome(email: string, data: { firstName: string; email: string }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    try {
      console.log('Initiating welcome email send to:', email);
      const result = await sendEmail({
        to: email,
        templateAlias: 'user-welcome',
        templateData: {
          firstName: data.firstName,
          email: data.email,
          loginUrl: `${window.location.origin}/login`,
          supportUrl: `${window.location.origin}/support`
        }
      });
      console.log('Welcome email API call result:', result);
      return result;
    } catch (error) {
      console.error('Detailed welcome email error:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to send welcome email: ${error.message}`);
      }
      throw new Error('Failed to send welcome email: Unknown error');
    }
  },

  async sendEmailConfirmation(email: string, data: { 
    firstName: string;
    verificationUrl: string;
  }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    try {
      console.log('Sending confirmation email to:', email);
      const result = await sendEmail({
        to: email,
        templateAlias: 'email-confirmation',
        templateData: {
          firstName: data.firstName,
          verificationUrl: data.verificationUrl,
          supportUrl: `${window.location.origin}/support`
        }
      });
      console.log('Confirmation email sent successfully:', result);
    } catch (error) {
      console.error('Error sending confirmation email:', error);
      throw error;
    }
  },

  async sendVendorCredentials(email: string, data: { 
    venueName: string;
    tempPassword: string;
  }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    try {
      console.log('Sending vendor credentials to:', email);
      const result = await sendEmail({
        to: email,
        templateAlias: 'vendor-welcome',
        templateData: {
          venueName: data.venueName,
          email: email,
          tempPassword: data.tempPassword,
          loginUrl: `${window.location.origin}/login`,
          supportUrl: `${window.location.origin}/support`
        }
      });
      console.log('Vendor credentials email sent successfully:', result);
      return result;
    } catch (error) {
      console.error('Error sending vendor credentials:', error);
      throw error;
    }
  },

  async sendPasswordReset(email: string, data: { resetLink: string }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    await sendEmail({
      to: email,
      templateAlias: 'password-reset',
      templateData: {
        resetLink: data.resetLink,
        supportUrl: `${window.location.origin}/support`
      }
    });
  },

  async sendAuctionWon(email: string, data: {
    firstName: string;
    courtName: string;
    venueName: string;
    finalPrice: number;
    auctionId: string;
  }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    await sendEmail({
      to: email,
      templateAlias: 'auction-won',
      templateData: {
        firstName: data.firstName,
        courtName: data.courtName,
        venueName: data.venueName,
        finalPrice: data.finalPrice,
        auctionDetailsUrl: `${window.location.origin}/auctions/${data.auctionId}`,
        supportUrl: `${window.location.origin}/support`
      }
    });
  },

  async sendAuctionLost(email: string, data: {
    firstName: string;
    courtName: string;
    venueName: string;
    finalPrice: number;
  }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    await sendEmail({
      to: email,
      templateAlias: 'auction-lost',
      templateData: {
        firstName: data.firstName,
        courtName: data.courtName,
        venueName: data.venueName,
        finalPrice: data.finalPrice,
        supportUrl: `${window.location.origin}/support`
      }
    });
  },

  async sendAuctionClosed(email: string, data: {
    venueName: string;
    courtName: string;
    auctionDate: string;
    timeSlot: string;
    startingPrice: number;
    finalPrice: number;
    winnerName: string;
    winnerEmail: string;
    auctionId: string;
  }) {
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    await sendEmail({
      to: email,
      templateAlias: 'auction-closed',
      templateData: {
        venueName: data.venueName,
        courtName: data.courtName,
        auctionDate: data.auctionDate,
        timeSlot: data.timeSlot,
        startingPrice: data.startingPrice,
        finalPrice: data.finalPrice,
        winnerName: data.winnerName,
        winnerEmail: data.winnerEmail,
        auctionDetailsUrl: `${window.location.origin}/vendor/auctions/${data.auctionId}`,
        dashboardUrl: `${window.location.origin}/vendor/dashboard`,
        supportUrl: `${window.location.origin}/support`
      }
    });
  }
};