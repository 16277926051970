import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useVenueProfile } from '../../hooks/useVenueProfile';
import { useUserProfile } from '../../hooks/useUserProfile';
import { LoadingSpinner } from '../LoadingSpinner';
import { useNotification } from '../../context/NotificationContext';
import { Camera, Plus, X } from 'lucide-react';

export const ProfileGeneral: React.FC = () => {
  const location = useLocation();
  const { showNotification } = useNotification();
  const isVendor = location.pathname.startsWith('/vendor');
  const isAdmin = location.pathname.startsWith('/admin');

  // Use appropriate profile hook based on user type
  const { profile: userProfile, loading: userLoading, saving: userSaving, updateProfile: updateUserProfile } = useUserProfile();
  const { profile: venueProfile, loading: venueLoading, saving: venueSaving, updateProfile: updateVenueProfile, uploadImage, removeImage } = useVenueProfile();

  const loading = isVendor ? venueLoading : userLoading;
  const saving = isVendor ? venueSaving : userSaving;

  const [formData, setFormData] = useState({
    // User fields
    firstName: '',
    lastName: '',
    email: '',
    phone: '',

    // Vendor fields
    name: '',
    description: '',
    website: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    openingHours: {
      weekdays: '',
      weekends: ''
    },
    facilities: [] as string[],
    profileImage: null as string | null,
    images: [] as string[]
  });

  // Update form data when profile is loaded
  useEffect(() => {
    if (isVendor && venueProfile) {
      setFormData({
        ...formData,
        ...venueProfile,
        openingHours: venueProfile.openingHours || { weekdays: '', weekends: '' },
        facilities: venueProfile.facilities || [],
        images: venueProfile.images || [],
        profileImage: venueProfile.profileImage
      });
    } else if (userProfile) {
      setFormData({
        ...formData,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        phone: userProfile.phone || ''
      });
    }
  }, [isVendor, venueProfile, userProfile]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'profile' | 'gallery') => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const imageUrl = await uploadImage(file, type);
        if (type === 'profile') {
          setFormData(prev => ({ ...prev, profileImage: imageUrl }));
        } else {
          setFormData(prev => ({ ...prev, images: [...prev.images, imageUrl] }));
        }
        showNotification('Image uploaded successfully', 'success');
      } catch (error) {
        showNotification('Failed to upload image', 'error');
      }
    }
  };

  const handleRemoveImage = async (imageUrl: string, type: 'profile' | 'gallery') => {
    try {
      await removeImage(imageUrl, type);
      if (type === 'profile') {
        setFormData(prev => ({ ...prev, profileImage: null }));
      } else {
        setFormData(prev => ({
          ...prev,
          images: prev.images.filter(img => img !== imageUrl)
        }));
      }
      showNotification('Image removed successfully', 'success');
    } catch (error) {
      showNotification('Failed to remove image', 'error');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isVendor) {
        await updateVenueProfile(formData);
      } else {
        await updateUserProfile({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone
        });
      }
      showNotification('Profile updated successfully', 'success');
    } catch (error) {
      showNotification('Failed to update profile', 'error');
    }
  };

  if (isVendor) {
    return (
      <div className="space-y-6">
        <div>
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">Venue Profile</h2>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Update your venue information and settings.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Profile Image */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Profile Image</label>
            <div className="flex items-center space-x-6">
              <div className="relative">
                {formData.profileImage ? (
                  <div className="relative">
                    <img
                      src={formData.profileImage}
                      alt="Venue Profile"
                      className="h-24 w-24 rounded-lg object-cover"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveImage(formData.profileImage!, 'profile')}
                      className="absolute -top-2 -right-2 bg-white dark:bg-dark-800 rounded-full p-1 shadow-sm"
                    >
                      <X className="h-4 w-4 text-gray-500" />
                    </button>
                  </div>
                ) : (
                  <div className="h-24 w-24 rounded-lg bg-gray-100 dark:bg-dark-700 flex items-center justify-center">
                    <Camera className="h-8 w-8 text-gray-400" />
                  </div>
                )}
                <label className="absolute bottom-0 right-0 rounded-full bg-white dark:bg-dark-700 p-1.5 shadow-sm border border-gray-200 dark:border-dark-600 cursor-pointer">
                  <Camera className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={e => handleImageUpload(e, 'profile')}
                  />
                </label>
              </div>
            </div>
          </div>

          {/* Venue Images */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Venue Images</label>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
              {formData.images?.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image}
                    alt={`Venue ${index + 1}`}
                    className="h-24 w-full object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(image, 'gallery')}
                    className="absolute top-1 right-1 bg-white rounded-full p-1 shadow-sm"
                  >
                    <X className="h-4 w-4 text-gray-500" />
                  </button>
                </div>
              ))}
              <label className="relative h-24 border-2 border-dashed border-gray-300 dark:border-dark-600 rounded-lg cursor-pointer hover:border-gray-400">
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <Plus className="h-8 w-8 text-gray-400" />
                  <span className="mt-2 text-sm text-gray-500">Add Images</span>
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={e => handleImageUpload(e, 'gallery')}
                />
              </label>
            </div>
          </div>

          {/* Basic Information */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Venue Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Website</label>
              <input
                type="url"
                value={formData.website}
                onChange={e => setFormData(prev => ({ ...prev, website: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Phone</label>
              <input
                type="tel"
                value={formData.phone}
                onChange={e => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          {/* Address */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Address</label>
              <input
                type="text"
                value={formData.address}
                onChange={e => setFormData(prev => ({ ...prev, address: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">City</label>
              <input
                type="text"
                value={formData.city}
                onChange={e => setFormData(prev => ({ ...prev, city: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">State</label>
              <input
                type="text"
                value={formData.state}
                onChange={e => setFormData(prev => ({ ...prev, state: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">ZIP Code</label>
              <input
                type="text"
                value={formData.zipCode}
                onChange={e => setFormData(prev => ({ ...prev, zipCode: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          {/* Opening Hours */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Weekday Hours</label>
              <input
                type="text"
                value={formData.openingHours?.weekdays}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  openingHours: { ...prev.openingHours, weekdays: e.target.value }
                }))}
                placeholder="e.g., 9:00 AM - 6:00 PM"
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Weekend Hours</label>
              <input
                type="text"
                value={formData.openingHours?.weekends}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  openingHours: { ...prev.openingHours, weekends: e.target.value }
                }))}
                placeholder="e.g., 10:00 AM - 4:00 PM"
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
            <textarea
              rows={4}
              value={formData.description}
              onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={saving}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          {isAdmin ? 'Admin Profile' : 'User Profile'}
        </h2>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Update your personal information and account settings.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">First Name</label>
            <input
              type="text"
              value={formData.firstName}
              onChange={e => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Last Name</label>
            <input
              type="text"
              value={formData.lastName}
              onChange={e => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
            <input
              type="email"
              value={formData.email}
              disabled
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-gray-50 dark:bg-dark-800 text-gray-500 dark:text-gray-400"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Phone</label>
            <input
              type="tel"
              value={formData.phone}
              onChange={e => setFormData(prev => ({ ...prev, phone: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
};