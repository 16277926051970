import { useState, useEffect } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';
import { uploadVenueImage } from '../services/storage.service';
import { updateVenueImages } from '../services/venue.service';

interface VenueProfile {
  name: string;
  description: string;
  email: string;
  phone: string;
  website: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  openingHours: {
    weekdays: string;
    weekends: string;
  };
  facilities: string[];
  profileImage: string | null;
  images: string[];
}

export const useVenueProfile = () => {
  const [profile, setProfile] = useState<VenueProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    const unsubscribe = onSnapshot(
      doc(db, 'venues', user.id),
      (doc) => {
        if (doc.exists()) {
          setProfile(doc.data() as VenueProfile);
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching venue profile:', err);
        setError(err instanceof Error ? err.message : 'Failed to load venue profile');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const updateProfile = async (updates: Partial<VenueProfile>) => {
    if (!user) return;
    setSaving(true);

    try {
      const venueRef = doc(db, 'venues', user.id);
      await updateDoc(venueRef, updates);
    } catch (err) {
      console.error('Error updating venue profile:', err);
      throw err;
    } finally {
      setSaving(false);
    }
  };

  const uploadImage = async (file: File, type: 'profile' | 'gallery') => {
    if (!user) return;
    setSaving(true);

    try {
      const imageUrl = await uploadVenueImage(user.id, file, type);
      return imageUrl;
    } catch (err) {
      console.error('Error uploading image:', err);
      throw err;
    } finally {
      setSaving(false);
    }
  };

  const removeImage = async (imageUrl: string, type: 'profile' | 'gallery') => {
    if (!user) return;
    setSaving(true);

    try {
      await updateVenueImages(user.id, imageUrl, type, 'remove');
    } catch (err) {
      console.error('Error removing image:', err);
      throw err;
    } finally {
      setSaving(false);
    }
  };

  return {
    profile,
    loading,
    saving,
    error,
    updateProfile,
    uploadImage,
    removeImage
  };
};