import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { DarkModeProvider } from './context/DarkModeContext';
import { AuthProvider } from './context/AuthContext';
import { NotificationProvider } from './context/NotificationContext';
import { LanguageProvider } from './context/LanguageContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import './index.css';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <DarkModeProvider>
          <NotificationProvider>
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </NotificationProvider>
        </DarkModeProvider>
      </AuthProvider>
    </ErrorBoundary>
  </StrictMode>
);