// src/components/admin/AdminStats.tsx
import React from 'react';
import { Users, Building2, Gavel, DollarSign } from 'lucide-react';
import { LoadingSpinner } from '../LoadingSpinner';

interface AdminStatsProps {
  stats: {
    totalUsers: number;
    activeVenues: number;
    liveAuctions: number;
    totalRevenue: number;
    userGrowth: number;
    venueGrowth: number;
    auctionGrowth: number;
    revenueGrowth: number;
    recentActivity: Array<{
      id: string;
      type: string;
      message: string;
      timestamp: string;
    }>;
  } | null;
}

export const AdminStats: React.FC<AdminStatsProps> = ({ stats }) => {
  console.log('AdminStats received:', stats);
  if (!stats) return null;

  const cards = [
    {
      title: 'Total Users',
      value: stats.totalUsers,
      change: stats.userGrowth,
      icon: Users,
      color: 'text-blue-600'
    },
    {
      title: 'Registered Venues',
      value: stats.activeVenues,
      change: stats.venueGrowth,
      icon: Building2,
      color: 'text-green-600'
    },
    {
      title: 'Live Auctions',
      value: stats.liveAuctions,
      change: stats.auctionGrowth,
      icon: Gavel,
      color: 'text-purple-600'
    },
    {
      title: 'Total Revenue',
      value: `$${stats.totalRevenue.toLocaleString()}`,
      change: stats.revenueGrowth,
      icon: DollarSign,
      color: 'text-yellow-600'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {cards.map((card) => {
          const Icon = card.icon;
          return (
            <div key={card.title} className="bg-white dark:bg-dark-800 overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Icon className={`h-6 w-6 ${card.color}`} />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                        {card.title}
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900 dark:text-white">
                          {card.value}
                        </div>
                        <div className={`ml-2 flex items-baseline text-sm font-semibold ${
                          card.change >= 0 
                            ? 'text-green-600 dark:text-green-500' 
                            : 'text-red-600 dark:text-red-500'
                        }`}>
                          {card.change >= 0 ? '+' : ''}{card.change.toFixed(1)}%
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Recent Activity Section */}
      <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Recent Activity</h3>
        <div className="flow-root">
          <ul className="-mb-8">
            {stats.recentActivity.map((activity, index) => (
              <li key={activity.id}>
                <div className="relative pb-8">
                  {index !== stats.recentActivity.length - 1 && (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-dark-700"
                      aria-hidden="true"
                    />
                  )}
                  <div className="relative flex space-x-3">
                    <div>
                      <span className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-dark-800 ${
                        activity.type === 'user' ? 'bg-blue-500' :
                        activity.type === 'venue' ? 'bg-green-500' :
                        activity.type === 'auction' ? 'bg-purple-500' :
                        'bg-gray-500'
                      }`}>
                        {activity.type === 'user' && <Users className="h-5 w-5 text-white" />}
                        {activity.type === 'venue' && <Building2 className="h-5 w-5 text-white" />}
                        {activity.type === 'auction' && <Gavel className="h-5 w-5 text-white" />}
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-900 dark:text-white">{activity.message}</p>
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500 dark:text-gray-400">
                        {new Date(activity.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
