import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, getDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';

export interface Bid {
  id: string;
  amount: number;
  currentHighest: number;
  isLeading: boolean;
  auctionId: string;
  auction?: {
    startTime: any;
    endTime: any;
    date: any;
    court?: {
      id: string;
      name: string;
      type: string;
      type2: 'Indoor' | 'Outdoor';
      venue?: {
        id: string;
        name: string;
        profileImage?: string;
      };
    };
  };
  status: 'active' | 'won' | 'lost';
  createdAt: any;
  userId: string;
}

export const useUserBids = () => {
  const [bids, setBids] = useState<Bid[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setBids([]);
      setLoading(false);
      return;
    }

    const bidsQuery = query(
      collection(db, 'bids'),
      where('userId', '==', user.id),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(
      bidsQuery,
      async (snapshot) => {
        try {
          const bidsPromises = snapshot.docs.map(async (bidDoc) => {
            const bidData = bidDoc.data();
            
            // Fetch auction details
            const auctionDoc = await getDoc(doc(db, 'auctions', bidData.auctionId));
            const auctionData = auctionDoc.data();

            // Fetch court details if auction exists
            let courtData = null;
            let venueData = null;
            if (auctionData?.courtId) {
              const courtDoc = await getDoc(doc(db, 'courts', auctionData.courtId));
              courtData = courtDoc.data();
              
              // Fetch venue details if court exists
              if (courtData?.venueId) {
                const venueDoc = await getDoc(doc(db, 'venues', courtData.venueId));
                venueData = venueDoc.data();
              }
            }

            return {
              id: bidDoc.id,
              amount: bidData.amount,
              currentHighest: bidData.currentHighest,
              isLeading: bidData.isLeading,
              auctionId: bidData.auctionId,
              auction: auctionData ? {
                startTime: auctionData.startTime,
                endTime: auctionData.endTime,
                date: auctionData.date,
                court: courtData ? {
                  id: auctionData.courtId,
                  name: courtData.name,
                  type: courtData.type,
                  type2: courtData.type2,
                  venue: venueData ? {
                    id: courtData.venueId,
                    name: venueData.name,
                    profileImage: venueData.profileImage
                  } : undefined
                } : undefined
              } : undefined,
              status: bidData.status,
              createdAt: bidData.createdAt,
              userId: bidData.userId
            } as Bid;
          });

          const bidsList = await Promise.all(bidsPromises);
          setBids(bidsList);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching bid details:', err);
          setError('Failed to load bids');
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching bids:', err);
        setError('Failed to load bids');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return { bids, loading, error };
}; 