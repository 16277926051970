import { useState, useEffect } from "react";
import { functions } from "../lib/firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../context/AuthContext";

interface PaymentMethod {
	id: string;
	card: {
		brand: string;
		last4: string;
		exp_month: number;
		exp_year: number;
	};
}

export const usePaymentMethods = () => {
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);
	const { user } = useAuth();

	useEffect(() => {
		const fetchPaymentMethods = async () => {
			if (!user) return;

			try {
				const listPaymentMethods = httpsCallable(functions, "listPaymentMethods");
				const result = await listPaymentMethods();
				setPaymentMethods(result.data as PaymentMethod[]);
			} catch (err) {
				setError(err instanceof Error ? err : new Error("Failed to fetch payment methods"));
			} finally {
				setIsLoading(false);
			}
		};

		fetchPaymentMethods();
	}, [user]);

	return { paymentMethods, isLoading, error };
};
