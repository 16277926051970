import React, { useState } from 'react';
import { X } from 'lucide-react';
import { sendPasswordResetEmail } from '../../services/auth.service';
import { useNotification } from '../../context/NotificationContext';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordModalProps {
  onClose: () => void;
}

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await sendPasswordResetEmail(email);
      setIsEmailSent(true);
      showNotification('Email di reset password inviata con successo', 'success');
    } catch (error) {
      showNotification(
        error instanceof Error ? error.message : 'Errore nell\'invio dell\'email di reset',
        'error'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg max-w-md w-full">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            Reset Password
          </h2>
          {!isEmailSent ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email
                </label>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2"
                  placeholder="you@example.com"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 border rounded-md text-sm font-medium"
                >
                  Annulla
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium"
                >
                  {isSubmitting ? 'Invio in corso...' : 'Invia email di reset'}
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Abbiamo inviato un'email con le istruzioni per reimpostare la password.
              </p>
              <button
                onClick={() => {
                  onClose();
                  navigate('/login');
                }}
                className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium"
              >
                Torna al login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};