import React, { useState } from 'react';
import { Users, Building2, Gavel, DollarSign, Activity, AlertTriangle, Plus } from 'lucide-react';
import { AdminStats } from '../../components/admin/AdminStats';
import { UsersList } from '../../components/admin/UsersList';
import { VenuesList } from '../../components/admin/VenuesList';
import { LiveAuctions } from '../../components/admin/LiveAuctions';
import { RevenueChart } from '../../components/admin/RevenueChart';
import { SystemAlerts } from '../../components/admin/SystemAlerts';
import { DarkModeToggle } from '../../components/DarkModeToggle';
import { ProfileMenu } from '../../components/modals/ProfileMenu';
import { AddVenueModal } from '../../components/admin/modals/AddVenueModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useAdminStats } from '../../hooks/useAdminStats';
import { LoadingSpinner } from '../../components/LoadingSpinner';

export const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { stats, loading, error } = useAdminStats();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleAddVenue = (venueData: any) => {
    console.log('New venue data:', venueData);
    setShowAddVenueModal(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">Error loading admin dashboard: {error}</p>
      </div>
    );
  }

  const tabs = [
    { id: 'overview', name: 'Overview', icon: Activity },
    { id: 'users', name: 'Users', icon: Users },
    { id: 'venues', name: 'Venues', icon: Building2 },
    { id: 'auctions', name: 'Live Auctions', icon: Gavel },
    { id: 'revenue', name: 'Revenue', icon: DollarSign },
    { id: 'alerts', name: 'System Alerts', icon: AlertTriangle },
  ];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-dark-900">
      <div className="flex flex-col h-screen">
        {/* Top Navigation Bar */}
        <div className="bg-white dark:bg-dark-800 shadow-sm z-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-bold text-gray-900 dark:text-white">Admin Dashboard</h1>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setShowAddVenueModal(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add New Venue
                </button>
                <DarkModeToggle />
                <button
                  onClick={() => setShowProfileMenu(true)}
                  className="p-2 rounded-full text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                >
                  <Users className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 overflow-hidden">
          {/* Sidebar */}
          <div className="hidden md:flex md:flex-shrink-0">
            <div className="w-64 bg-white dark:bg-dark-800 border-r dark:border-dark-700">
              <nav className="mt-4">
                {tabs.map((tab) => {
                  const Icon = tab.icon;
                  return (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`w-full flex items-center px-4 py-3 text-sm ${
                        activeTab === tab.id
                          ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 border-l-4 border-indigo-600 dark:border-indigo-400'
                          : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-dark-700'
                      }`}
                    >
                      <Icon className="h-5 w-5 mr-3" />
                      {tab.name}
                    </button>
                  );
                })}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 overflow-auto p-8 pb-20 md:pb-8">
            {activeTab === 'overview' && <AdminStats stats={stats} />}
            {activeTab === 'users' && <UsersList />}
            {activeTab === 'venues' && <VenuesList />}
            {activeTab === 'auctions' && <LiveAuctions />}
            {activeTab === 'revenue' && <RevenueChart />}
            {activeTab === 'alerts' && <SystemAlerts />}
          </div>
        </div>
      </div>

      {showProfileMenu && (
        <ProfileMenu
          onClose={() => setShowProfileMenu(false)}
          onLogout={handleLogout}
        />
      )}

      {showAddVenueModal && (
        <AddVenueModal
          onClose={() => setShowAddVenueModal(false)}
          onAdd={handleAddVenue}
        />
      )}
    </div>
  );
};