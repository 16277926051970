import React, { useState } from 'react';
import { Building2, CreditCard, ShieldCheck, AlertTriangle, Camera, MapPin, Phone, Mail, Globe } from 'lucide-react';

interface VenueInfo {
  id: string;
  businessName: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  email: string;
  website: string;
  image: string;
  openingHours: {
    weekdays: string;
    weekends: string;
  };
  facilities: string[];
  verificationStatus: 'pending' | 'verified' | 'rejected';
}

export const VendorProfile: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [venueInfo, setVenueInfo] = useState<VenueInfo>({
    id: '1',
    businessName: 'Downtown Sports Complex',
    description: 'Premier sports facility offering state-of-the-art courts and amenities.',
    address: '123 Sports Ave',
    city: 'Downtown',
    state: 'NY',
    zipCode: '10001',
    phone: '+1 (555) 123-4567',
    email: 'info@downtownsports.com',
    website: 'www.downtownsports.com',
    image: 'https://images.unsplash.com/photo-1584013106544-05f2888faef2?auto=format&fit=crop&q=80&w=1200',
    openingHours: {
      weekdays: '6:00 AM - 10:00 PM',
      weekends: '7:00 AM - 9:00 PM',
    },
    facilities: [
      'Pro Shop',
      'Locker Rooms',
      'Cafe',
      'Parking',
      'Equipment Rental',
      'Training Programs',
    ],
    verificationStatus: 'verified'
  });

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setVenueInfo(prev => ({ ...prev, image: imageUrl }));
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    // Here you would typically make an API call to update the venue information
    // This updated information would then be reflected in the user-facing components
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between mb-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Venue Profile</h2>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Manage your venue information and settings
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            onClick={() => isEditing ? handleSave() : setIsEditing(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {isEditing ? 'Save Changes' : 'Edit Profile'}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {/* Venue Image */}
        <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">Venue Image</h3>
          </div>
          <div className="relative">
            <img
              src={venueInfo.image}
              alt={venueInfo.businessName}
              className="w-full h-64 object-cover rounded-lg"
            />
            {isEditing && (
              <label className="absolute bottom-4 right-4 cursor-pointer">
                <div className="flex items-center px-4 py-2 bg-white dark:bg-dark-800 rounded-md shadow-sm">
                  <Camera className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">Change Image</span>
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </label>
            )}
          </div>
        </div>

        {/* Basic Information */}
        <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Building2 className="h-5 w-5 text-gray-400 dark:text-gray-500" />
              <h3 className="ml-2 text-lg font-medium text-gray-900 dark:text-white">
                Basic Information
              </h3>
            </div>
            {venueInfo.verificationStatus === 'verified' && (
              <ShieldCheck className="h-5 w-5 text-green-500" />
            )}
          </div>
          
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Business Name
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.businessName}
                onChange={e => setVenueInfo(prev => ({ ...prev, businessName: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Description
              </label>
              <textarea
                disabled={!isEditing}
                value={venueInfo.description}
                onChange={e => setVenueInfo(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Address
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.address}
                onChange={e => setVenueInfo(prev => ({ ...prev, address: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                City
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.city}
                onChange={e => setVenueInfo(prev => ({ ...prev, city: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                State
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.state}
                onChange={e => setVenueInfo(prev => ({ ...prev, state: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                ZIP Code
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.zipCode}
                onChange={e => setVenueInfo(prev => ({ ...prev, zipCode: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Phone className="h-5 w-5 text-gray-400 dark:text-gray-500" />
            <h3 className="ml-2 text-lg font-medium text-gray-900 dark:text-white">
              Contact Information
            </h3>
          </div>
          
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Phone Number
              </label>
              <input
                type="tel"
                disabled={!isEditing}
                value={venueInfo.phone}
                onChange={e => setVenueInfo(prev => ({ ...prev, phone: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email
              </label>
              <input
                type="email"
                disabled={!isEditing}
                value={venueInfo.email}
                onChange={e => setVenueInfo(prev => ({ ...prev, email: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Website
              </label>
              <input
                type="url"
                disabled={!isEditing}
                value={venueInfo.website}
                onChange={e => setVenueInfo(prev => ({ ...prev, website: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>
        </div>

        {/* Opening Hours */}
        <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Clock className="h-5 w-5 text-gray-400 dark:text-gray-500" />
            <h3 className="ml-2 text-lg font-medium text-gray-900 dark:text-white">
              Opening Hours
            </h3>
          </div>
          
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Weekdays
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.openingHours.weekdays}
                onChange={e => setVenueInfo(prev => ({
                  ...prev,
                  openingHours: { ...prev.openingHours, weekdays: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Weekends
              </label>
              <input
                type="text"
                disabled={!isEditing}
                value={venueInfo.openingHours.weekends}
                onChange={e => setVenueInfo(prev => ({
                  ...prev,
                  openingHours: { ...prev.openingHours, weekends: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>
        </div>

        {/* Facilities */}
        <div className="bg-white dark:bg-dark-800 shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Building2 className="h-5 w-5 text-gray-400 dark:text-gray-500" />
            <h3 className="ml-2 text-lg font-medium text-gray-900 dark:text-white">
              Facilities
            </h3>
          </div>
          
          {isEditing ? (
            <div className="space-y-2">
              {venueInfo.facilities.map((facility, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={facility}
                    onChange={e => {
                      const newFacilities = [...venueInfo.facilities];
                      newFacilities[index] = e.target.value;
                      setVenueInfo(prev => ({ ...prev, facilities: newFacilities }));
                    }}
                    className="block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
                  />
                  <button
                    onClick={() => {
                      const newFacilities = venueInfo.facilities.filter((_, i) => i !== index);
                      setVenueInfo(prev => ({ ...prev, facilities: newFacilities }));
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              ))}
              <button
                onClick={() => {
                  setVenueInfo(prev => ({
                    ...prev,
                    facilities: [...prev.facilities, '']
                  }));
                }}
                className="mt-2 inline-flex items-center px-3 py-2 border border-gray-300 dark:border-dark-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Facility
              </button>
            </div>
          ) : (
            <ul className="grid grid-cols-2 gap-3">
              {venueInfo.facilities.map((facility, index) => (
                <li key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                  <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 dark:bg-indigo-400 mr-2" />
                  {facility}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};