import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export const Footer: React.FC = () => {
  const { language } = useLanguage();
  
  return (
    <footer className="bg-white dark:bg-dark-800 border-t border-gray-200 dark:border-dark-700">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {/* Desktop Footer */}
        <div className="hidden sm:flex flex-col items-center space-y-4">
          {/* Brand */}
          <div className="text-2xl font-black tracking-wider text-gray-900 dark:text-white">
            BIDCOURT
          </div>

          {/* Navigation Links */}
          <div className="flex items-center space-x-6">
            <Link 
              to="/privacy" 
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
            >
              {language === 'en' ? 'Privacy Policy' : 'Privacy Policy'}
            </Link>
            <Link 
              to="/terms" 
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
            >
              {language === 'en' ? 'Terms of Service' : 'Termini di Servizio'}
            </Link>
            <Link 
              to="/cookie-policy" 
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
            >
              {language === 'en' ? 'Cookie Policy' : 'Policy dei Cookie'}
            </Link>
            <a 
              href="mailto:info@bidcourt.it" 
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
            >
              {language === 'en' ? 'Contact' : 'Contatti'}
            </a>
          </div>
        </div>

        {/* Mobile Footer */}
        <div className="sm:hidden flex flex-col items-center space-y-4">
          {/* Logo */}
          <span className="text-xl font-black tracking-wider text-gray-900 dark:text-white">
            BIDCOURT
          </span>
          
          {/* Links */}
          <div className="flex flex-wrap justify-center gap-2 text-sm">
            <Link 
              to="/privacy" 
              className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
            >
              Privacy
            </Link>
            <span className="text-gray-300">•</span>
            <Link 
              to="/terms" 
              className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
            >
              Terms
            </Link>
            <span className="text-gray-300">•</span>
            <Link 
              to="/cookie-policy" 
              className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
            >
              Cookies
            </Link>
            <span className="text-gray-300">•</span>
            <a 
              href="mailto:info@bidcourt.it" 
              className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
            >
              Contact
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-4 pt-4 border-t border-gray-200 dark:border-dark-700">
          <p className="text-xs text-gray-400 dark:text-gray-500 text-center">
            © {new Date().getFullYear()} BidCourt
          </p>
        </div>
      </div>
    </footer>
  );
};