import React, { useState } from 'react';
import { Calendar, ChevronDown, MapPin, ChevronLeft, ChevronRight } from 'lucide-react';
import { format, addDays, startOfMonth, endOfMonth, eachDayOfInterval, getDay } from 'date-fns';
import { useLanguage } from '../context/LanguageContext';

interface FilterBarProps {
  onFilterChange: (filters: {
    location: string;
    sport: string;
    date: string;
    time: string;
  }) => void;
}

const sports = [
  'Padel',
  'Tennis', 
  'Calcio a 5',
  'Calcio a 8',
  'Calcio a 11',
  'Padbol',
  'Basketball',
  'Pickleball',
  'Beach Tennis',
  'Beach Volley',
  'Volleyball'
];

const times = Array.from({ length: 31 }, (_, i) => {
  const hour = Math.floor(i / 2) + 8;
  const minute = i % 2 === 0 ? '00' : '30';
  return `${hour.toString().padStart(2, '0')}:${minute}`;
}).filter(time => {
  const hour = parseInt(time.split(':')[0]);
  return hour <= 23;
});

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const WEEKDAY_SHORTS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const FilterBar: React.FC<FilterBarProps> = ({ onFilterChange }) => {
  const { t } = useLanguage();
  const [location, setLocation] = useState('');
  const [selectedSport, setSelectedSport] = useState('Tennis');
  const [selectedDate, setSelectedDate] = useState(t('common.today'));
  const [selectedTime, setSelectedTime] = useState('21:00');
  const [showSportDropdown, setShowSportDropdown] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const getDaysInMonth = () => {
    const start = startOfMonth(currentMonth);
    const end = endOfMonth(currentMonth);
    return eachDayOfInterval({ start, end });
  };

  const days = getDaysInMonth();

  const handleSearch = () => {
    onFilterChange({
      location,
      sport: selectedSport,
      date: selectedDate,
      time: selectedTime,
    });
  };

  return (
    <div className="bg-gray-50 dark:bg-dark-900 rounded-lg shadow-sm p-4 backdrop-blur-sm mb-2 sm:mb-4">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Location Search */}
        <div className="relative flex-1">
          <div className="flex items-center border rounded-lg dark:border-dark-600">
            <MapPin className="h-5 w-5 text-gray-400 dark:text-gray-500 ml-3" />
            <input
              type="text"
              placeholder={t('home.searchPlaceholder')}
              className="w-full p-3 rounded-lg bg-transparent dark:text-white focus:outline-none"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>

        {/* Sport Selection */}
        <div className="relative w-full md:w-40">
          <button
            onClick={() => setShowSportDropdown(!showSportDropdown)}
            className="w-full p-3 border dark:border-dark-600 rounded-lg flex items-center justify-between bg-white dark:bg-dark-800 dark:text-white"
          >
            <span className="truncate">{selectedSport}</span>
            <ChevronDown className="h-4 w-4 text-gray-400 dark:text-gray-500 flex-shrink-0 ml-2" />
          </button>
          {showSportDropdown && (
            <div className="absolute z-50 w-full mt-1 bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg shadow-lg max-h-48 overflow-y-auto">
              {sports.map((sport) => (
                <button
                  key={sport}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 dark:hover:bg-dark-700 dark:text-white"
                  onClick={() => {
                    setSelectedSport(sport);
                    setShowSportDropdown(false);
                  }}
                >
                  {sport}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Date Selection */}
        <div className="relative w-full md:w-40">
          <button
            onClick={() => setShowDatePicker(!showDatePicker)}
            className="w-full p-3 border dark:border-dark-600 rounded-lg flex items-center justify-between bg-white dark:bg-dark-800 dark:text-white"
          >
            <div className="flex items-center min-w-0">
              <Calendar className="h-4 w-4 text-gray-400 dark:text-gray-500 mr-2 flex-shrink-0" />
              <span className="truncate">{selectedDate}</span>
            </div>
            <ChevronDown className="h-4 w-4 text-gray-400 dark:text-gray-500 flex-shrink-0 ml-2" />
          </button>
          {showDatePicker && (
            <div className="absolute z-50 w-80 mt-1 bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg shadow-lg p-4">
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={() => {
                    const newDate = new Date(currentMonth);
                    newDate.setMonth(currentMonth.getMonth() - 1);
                    setCurrentMonth(newDate);
                  }}
                  className="p-1 hover:bg-gray-100 dark:hover:bg-dark-700 rounded"
                >
                  <ChevronLeft className="h-4 w-4 dark:text-white" />
                </button>
                <span className="font-medium dark:text-white">
                  {format(currentMonth, 'MMMM yyyy')}
                </span>
                <button
                  onClick={() => {
                    const newDate = new Date(currentMonth);
                    newDate.setMonth(currentMonth.getMonth() + 1);
                    setCurrentMonth(newDate);
                  }}
                  className="p-1 hover:bg-gray-100 dark:hover:bg-dark-700 rounded"
                >
                  <ChevronRight className="h-4 w-4 dark:text-white" />
                </button>
              </div>
              <div className="grid grid-cols-7 gap-1">
                {WEEKDAY_SHORTS.map((day, index) => (
                  <div key={index} className="text-center text-sm text-gray-500 dark:text-gray-400">
                    {day}
                  </div>
                ))}
                {days.map((day, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedDate(format(day, 'MMM d, yyyy'));
                      setShowDatePicker(false);
                    }}
                    className={`p-2 text-center rounded-full hover:bg-indigo-50 dark:hover:bg-indigo-900 ${
                      format(day, 'MMM d, yyyy') === selectedDate
                        ? 'bg-indigo-600 text-white dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600'
                        : 'dark:text-white'
                    }`}
                  >
                    {format(day, 'd')}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Time Selection */}
        <div className="relative w-full md:w-40">
          <button
            onClick={() => setShowTimePicker(!showTimePicker)}
            className="w-full p-3 border dark:border-dark-600 rounded-lg flex items-center justify-between bg-white dark:bg-dark-800 dark:text-white"
          >
            <span className="truncate">{selectedTime}</span>
            <ChevronDown className="h-4 w-4 text-gray-400 dark:text-gray-500 flex-shrink-0 ml-2" />
          </button>
          {showTimePicker && (
            <div className="absolute z-50 w-full mt-1 bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg shadow-lg max-h-60 overflow-y-auto">
              {times.map((time) => (
                <button
                  key={time}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 dark:hover:bg-dark-700 dark:text-white"
                  onClick={() => {
                    setSelectedTime(time);
                    setShowTimePicker(false);
                  }}
                >
                  {time}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Search Button */}
        <button
          onClick={handleSearch}
          className="w-full md:w-auto bg-indigo-600 text-white px-8 py-3 rounded-lg hover:bg-indigo-700 transition-colors whitespace-nowrap"
        >
          {t('common.search')}
        </button>
      </div>
    </div>
  );
};