import { 
  doc, 
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp 
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db, firebaseConfig } from '../lib/firebase';
import { emailService } from './email.service';

// Create a secondary Firebase app for venue user creation
const secondaryApp = initializeApp(firebaseConfig, 'secondary');
const secondaryAuth = getAuth(secondaryApp);

// Helper function to generate temporary password
const generateTempPassword = () => {
  const length = 12;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

// Helper function to check if user is admin
const checkAdminStatus = async (uid: string): Promise<boolean> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (!userDoc.exists()) {
      console.error('User document not found');
      return false;
    }
    
    const userData = userDoc.data();
    console.log('User data:', userData); // Debug log
    
    if (userData?.role !== 'admin') {
      console.error('User is not an admin. Role:', userData?.role);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const createVenue = async (venueData: any) => {
  try {
    // Store current admin credentials
    const adminUser = auth.currentUser;
    if (!adminUser) {
      throw new Error('No authenticated user found');
    }

    // Verify admin status
    const adminDoc = await getDoc(doc(db, 'users', adminUser.uid));
    if (!adminDoc.exists() || adminDoc.data()?.role !== 'admin') {
      throw new Error('Only administrators can create venues');
    }

    // Generate temporary password
    const tempPassword = generateTempPassword();

    try {
      // Create auth account for venue using secondary app
      const userCredential = await createUserWithEmailAndPassword(
        secondaryAuth,
        venueData.email,
        tempPassword
      );

      const venueId = userCredential.user.uid;

      // Create user document for venue owner
      const userRef = doc(db, 'users', venueId);
      const userDocData = {
        email: venueData.email,
        firstName: venueData.name,
        lastName: 'Admin',
        role: 'vendor',
        status: 'active',
        hasChangedPassword: false,
        createdAt: serverTimestamp(),
        createdBy: adminUser.uid
      };

      await setDoc(userRef, userDocData);

      // Create venue document
      const venueRef = doc(db, 'venues', venueId);
      const venueDocData = {
        name: venueData.name,
        email: venueData.email,
        phone: venueData.phone,
        address: venueData.address,
        status: 'pending',
        verificationStatus: 'pending',
        courtsCount: 0,
        revenue: 0,
        ownerId: venueId,
        createdAt: serverTimestamp(),
        createdBy: adminUser.uid,
        lastUpdated: serverTimestamp()
      };

      await setDoc(venueRef, venueDocData);

      // Send welcome email
      await emailService.sendVendorCredentials(venueData.email, {
        venueName: venueData.name,
        tempPassword: tempPassword
      });

      // Sign out from secondary auth
      await secondaryAuth.signOut();

      return {
        id: venueId,
        ...venueDocData
      };
    } catch (error) {
      console.error('Error in venue creation:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error creating venue:', error);
    throw error;
  }
};

export const updateVenueProfile = async (venueId: string, updates: any) => {
  try {
    const venueRef = doc(db, 'venues', venueId);
    await updateDoc(venueRef, updates);
  } catch (error) {
    console.error('Error updating venue profile:', error);
    throw error;
  }
};

export const updateVenueImages = async (
  venueId: string,
  imageUrl: string,
  type: 'profile' | 'gallery',
  action: 'add' | 'remove' = 'add'
): Promise<void> => {
  try {
    const venueRef = doc(db, 'venues', venueId);
    
    if (type === 'profile') {
      await updateDoc(venueRef, {
        profileImage: action === 'add' ? imageUrl : null
      });
    } else {
      if (action === 'add') {
        await updateDoc(venueRef, {
          images: arrayUnion(imageUrl)
        });
      } else {
        await updateDoc(venueRef, {
          images: arrayRemove(imageUrl)
        });
      }
    }
  } catch (error) {
    console.error('Error updating venue images:', error);
    throw new Error('Failed to update venue images');
  }
};