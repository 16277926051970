import { 
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { db } from '../lib/firebase';

interface CourtData {
  name: string;
  type: string;
  type2: 'Indoor' | 'Outdoor';
  venueId: string;
}

export const createCourt = async (courtData: CourtData, venueId: string): Promise<string> => {
  try {
    console.log('Creating court with data:', courtData);
    const courtRef = await addDoc(collection(db, 'courts'), {
      ...courtData,
      venueId,
      status: 'active',
      createdAt: serverTimestamp(),
      type: courtData.type || 'Padel',
      type2: courtData.type2
    });
    console.log('Court created in Firestore:', courtRef.id);
    return courtRef.id;
  } catch (error) {
    console.error('Error creating court:', error);
    throw error;
  }
};

export const deleteCourt = async (courtId: string): Promise<void> => {
  try {
    // Check for active auctions
    const auctionsQuery = query(
      collection(db, 'auctions'),
      where('courtId', '==', courtId),
      where('status', '==', 'active')
    );
    
    const auctionsSnapshot = await getDocs(auctionsQuery);
    
    if (!auctionsSnapshot.empty) {
      throw new Error('Cannot delete court with active auctions');
    }

    // Delete the court
    await deleteDoc(doc(db, 'courts', courtId));
  } catch (error) {
    console.error('Error deleting court:', error);
    throw error;
  }
};