export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string): string[] => {
  const errors: string[] = [];
  
  if (password.length < 8) {
    errors.push('Password must be at least 8 characters long');
  }
  if (!/[A-Z]/.test(password)) {
    errors.push('Password must contain at least one uppercase letter');
  }
  if (!/[a-z]/.test(password)) {
    errors.push('Password must contain at least one lowercase letter');
  }
  if (!/[0-9]/.test(password)) {
    errors.push('Password must contain at least one number');
  }
  
  return errors;
};

export const validateBidAmount = (amount: number, currentBid: number): string | null => {
  if (isNaN(amount)) {
    return 'Please enter a valid number';
  }
  if (amount <= currentBid) {
    return `Bid must be higher than current bid ($${currentBid})`;
  }
  if (amount > currentBid * 2) {
    return 'Bid cannot be more than double the current bid';
  }
  return null;
};