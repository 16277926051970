import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, orderBy, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface Auction {
  id: string;
  courtName: string;
  venueName: string;
  currentBid: number;
  startTime: string;
  endTime: string;
  participants: number;
  status: string;
  courtId: string;
  venueId: string;
  date: string;
  basePrice: number;
  createdAt: Date;
  court: {
    id: string;
    name: string;
    type: string;
    type2: string;
    image: string | null;
    venue: {
      id: string;
      name: string;
      profileImage?: string;
    };
  };
  auctionEndTime: string;
}

export const useAdminAuctions = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const auctionsRef = collection(db, 'auctions');
    const q = query(
      auctionsRef,
      where('status', '==', 'active'),
      orderBy('auctionEndTime', 'asc')
    );

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        try {
          const auctionsData = await Promise.all(
            snapshot.docs.map(async (auctionDoc) => {
              const data = auctionDoc.data();
              
              // Get court data
              const courtDoc = await getDoc(doc(db, 'courts', data.courtId));
              const courtData = courtDoc.exists() ? courtDoc.data() : null;
              
              // Get venue data
              const venueDoc = await getDoc(doc(db, 'venues', data.venueId));
              const venueData = venueDoc.exists() ? venueDoc.data() : null;

              // Get bids information
              const bidsRef = collection(db, 'bids');
              const bidsQuery = query(bidsRef, where('auctionId', '==', auctionDoc.id));
              const bidsSnapshot = await getDocs(bidsQuery);
              
              const uniqueParticipants = new Set(
                bidsSnapshot.docs.map(bid => bid.data().userId)
              );

              return {
                id: auctionDoc.id,
                ...data,
                courtName: courtData?.name || 'Unknown Court',
                venueName: venueData?.name || 'Unknown Venue',
                participants: uniqueParticipants.size,
                totalBids: bidsSnapshot.size,
                startTime: data.startTime,
                endTime: data.endTime,
                date: data.date,
                basePrice: data.basePrice,
                createdAt: typeof data.createdAt === 'string' ? new Date(data.createdAt) : data.createdAt?.toDate?.() || new Date(),
                court: {
                  id: data.courtId,
                  name: courtData?.name || 'Unknown Court',
                  type: courtData?.type || 'Unknown Type',
                  type2: courtData?.type2 || 'Outdoor',
                  image: courtData?.image || null,
                  venue: {
                    id: data.venueId,
                    name: venueData?.name || 'Unknown Venue',
                    profileImage: venueData?.profileImage
                  }
                },
                auctionEndTime: data.auctionEndTime
              } as Auction;
            })
          );

          // Sort auctions by countdown time (closest to ending first)
          const sortedAuctions = auctionsData.sort((a, b) => {
            const aEndTime = new Date(a.auctionEndTime).getTime();
            const bEndTime = new Date(b.auctionEndTime).getTime();
            return aEndTime - bEndTime;
          });

          setAuctions(sortedAuctions);
          setLoading(false);
        } catch (err) {
          console.error('Error processing auctions:', err);
          setError(err instanceof Error ? err.message : 'Failed to load auctions');
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching auctions:', err);
        setError(err instanceof Error ? err.message : 'Failed to load auctions');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { auctions, loading, error };
};