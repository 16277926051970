import { useState, useEffect } from 'react';
import { query, collection, where, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface Court {
  id: string;
  name: string;
  type: string;
  venueId: string;
  status: string;
  createdAt: Date;
  type2: 'Indoor' | 'Outdoor';
}

interface UseCourtOptions {
  venueId?: string;
}

export const useCourts = (options: UseCourtOptions = {}) => {
  const [courts, setCourts] = useState<Court[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const courtsRef = collection(db, 'courts');
    
    // Build query based on options
    let courtsQuery = query(courtsRef);
    
    if (options.venueId) {
      courtsQuery = query(
        courtsRef,
        where('venueId', '==', options.venueId),
        orderBy('createdAt', 'desc')
      );
    }

    const unsubscribe = onSnapshot(
      courtsQuery,
      (snapshot) => {
        const courtsData = snapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Court data from DB:', data);
          return {
            id: doc.id,
            ...data,
            type2: data.type2 || 'Outdoor',
            createdAt: data.createdAt?.toDate()
          };
        }) as Court[];
        
        setCourts(courtsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching courts:', err);
        setError(err instanceof Error ? err.message : 'Failed to load courts');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [options.venueId]);

  return { courts, loading, error };
};