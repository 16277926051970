import React from 'react';
import { useLanguage } from '../context/LanguageContext';

interface Subsection {
  title: string;
  content: string;
}

interface Section {
  title: string;
  subsections: Subsection[];
}

interface CookieContent {
  title: string;
  sections: Section[];
  lastUpdated: string;
}

interface CookieData {
  en: CookieContent;
  it: CookieContent;
}

const cookieContent: CookieData = {
  en: {
    title: "Bidcourt Cookie Policy",
    sections: [
      {
        title: "1. What are Cookies?",
        subsections: [
          {
            title: "",
            content: "Cookies are small text files stored on your device when you visit a website. They help us provide a better user experience and understand how our platform is used."
          }
        ]
      },
      {
        title: "2. Types of Cookies We Use",
        subsections: [
          {
            title: "",
            content: `• Essential Cookies: Necessary for the basic functionality of our website
• Analytics Cookies: Used to understand website traffic and user interactions`
          }
        ]
      },
      {
        title: "3. Specific Cookie Usage",
        subsections: [
          {
            title: "",
            content: `• Google Analytics: We use Google Analytics to collect anonymous usage data
• Purpose: Improve website performance and user experience
• No Personal Identifying Information is collected through these cookies`
          }
        ]
      },
      {
        title: "4. Cookie Management",
        subsections: [
          {
            title: "",
            content: `You can control or disable cookies through your browser settings:

• Chrome: Settings > Privacy and Security > Cookies and other site data
• Firefox: Options > Privacy & Security > Cookies and Site Data
• Safari: Preferences > Privacy > Cookies and Website Data
• Edge: Settings > Cookies and site permissions > Cookies and site data`
          }
        ]
      },
      {
        title: "5. Third-Party Cookies",
        subsections: [
          {
            title: "",
            content: "We do not use third-party cookies for advertising or marketing purposes."
          }
        ]
      },
      {
        title: "6. Consent",
        subsections: [
          {
            title: "",
            content: "By using Bidcourt, you consent to our use of cookies as described in this policy."
          }
        ]
      },
      {
        title: "7. Updates to Cookie Policy",
        subsections: [
          {
            title: "",
            content: "We may update this policy periodically. Please review it regularly."
          }
        ]
      },
      {
        title: "8. Contact",
        subsections: [
          {
            title: "",
            content: "For any questions about our Cookie Policy, contact: info@bidcourt.it"
          }
        ]
      }
    ],
    lastUpdated: "Last Updated: 26/11/2024"
  },
  it: {
    title: "Policy dei Cookie",
    sections: [
      {
        title: "1. Cosa sono i Cookie?",
        subsections: [
          {
            title: "",
            content: "I cookie sono piccoli file di testo memorizzati sul tuo dispositivo quando visiti un sito web. Ci aiutano a fornire una migliore esperienza utente e a comprendere come viene utilizzata la nostra piattaforma."
          }
        ]
      },
      {
        title: "2. Tipologie di Cookie Utilizzati",
        subsections: [
          {
            title: "",
            content: `• Cookie Essenziali: Necessari per la funzionalità di base del sito web
• Cookie Analitici: Utilizzati per comprendere il traffico del sito web e le interazioni degli utenti`
          }
        ]
      },
      {
        title: "3. Utilizzo Specifico dei Cookie",
        subsections: [
          {
            title: "",
            content: `• Google Analytics: Utilizziamo Google Analytics per raccogliere dati di utilizzo anonimi
• Scopo: Migliorare le prestazioni del sito web e l'esperienza utente
• Nessuna Informazione Personale Identificabile viene raccolta tramite questi cookie`
          }
        ]
      },
      {
        title: "4. Gestione dei Cookie",
        subsections: [
          {
            title: "",
            content: `Puoi controllare o disabilitare i cookie attraverso le impostazioni del tuo browser:

• Chrome: Impostazioni > Privacy e sicurezza > Cookie e dati del sito
• Firefox: Opzioni > Privacy e sicurezza > Cookie e dati del sito
• Safari: Preferenze > Privacy > Cookie e dati dei siti web
• Edge: Impostazioni > Cookie e autorizzazioni sito > Cookie e dati del sito`
          }
        ]
      },
      {
        title: "5. Cookie di Terze Parti",
        subsections: [
          {
            title: "",
            content: "Non utilizziamo cookie di terze parti per pubblicità o scopi di marketing."
          }
        ]
      },
      {
        title: "6. Consenso",
        subsections: [
          {
            title: "",
            content: "Utilizzando Bidcourt, acconsenti all'uso dei cookie come descritto in questa politica."
          }
        ]
      },
      {
        title: "7. Aggiornamenti alla Politica dei Cookie",
        subsections: [
          {
            title: "",
            content: "Potremmo aggiornare periodicamente questa politica. Ti invitiamo a consultarla regolarmente."
          }
        ]
      },
      {
        title: "8. Contatti",
        subsections: [
          {
            title: "",
            content: "Per domande sulla nostra Politica dei Cookie, contatta: info@bidcourt.it"
          }
        ]
      }
    ],
    lastUpdated: "Ultimo Aggiornamento: 26/11/2024"
  }
};

const CookiePolicy: React.FC = () => {
  const { language } = useLanguage();
  const content = cookieContent[language as keyof typeof cookieContent];

  return (
    <div className="bg-gray-50 dark:bg-dark-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          {content.title}
        </h1>

        <div className="space-y-8 text-gray-700 dark:text-gray-300">
          {content.sections.map((section, index) => (
            <section key={index} className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className="mb-4">
                    {subsection.title && (
                      <h3 className="text-lg font-medium mb-2">
                        {subsection.title}
                      </h3>
                    )}
                    <div className="whitespace-pre-line text-base">
                      {subsection.content}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ))}

          <p className="text-sm text-gray-500 dark:text-gray-400 mt-8">
            {content.lastUpdated}
          </p>
        </div>
      </div>
    </div>
  );
};

export { CookiePolicy }; 