import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { createCourt } from '../../services/court.service';
import { useNotification } from '../../context/NotificationContext';

interface AddCourtModalProps {
  onClose: () => void;
  onAdd: (court: any) => void;
}

export const AddCourtModal: React.FC<AddCourtModalProps> = ({ onClose, onAdd }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [formData, setFormData] = useState({
    name: '',
    type: 'Tennis',
    type2: 'Indoor' as 'Indoor' | 'Outdoor'
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) return;

    console.log('Submitting court data:', formData);

    try {
      const courtId = await createCourt({
        name: formData.name,
        type: formData.type,
        type2: formData.type2,
        venueId: user.id
      }, user.id);

      console.log('Court created with ID:', courtId);
      onAdd({ id: courtId, ...formData });
      onClose();
    } catch (error) {
      showNotification(
        error instanceof Error ? error.message : 'Failed to create court',
        'error'
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-6 border-b dark:border-dark-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {t('vendor.addNewCourt')}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('vendor.courtName')} *
            </label>
            <input
              type="text"
              required
              value={formData.name}
              onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('vendor.courtType')}
              </label>
              <select
                value={formData.type}
                onChange={e => setFormData(prev => ({ ...prev, type: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              >
                <option value="Padel">Padel</option>
                <option value="Tennis">Tennis</option>
                <option value="Calcio a 5">Calcio a 5</option>
                <option value="Calcio a 8">Calcio a 8</option>
                <option value="Calcio a 11">Calcio a 11</option>
                <option value="Padbol">Padbol</option>
                <option value="Basketball">Basketball</option>
                <option value="Pickleball">Pickleball</option>
                <option value="Beach Tennis">Beach Tennis</option>
                <option value="Beach Volley">Beach Volley</option>
                <option value="Volleyball">Volleyball</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('vendor.location')}
              </label>
              <select
                value={formData.type2}
                onChange={e => {
                  const value = e.target.value as 'Indoor' | 'Outdoor';
                  console.log('Setting type2 to:', value);
                  setFormData(prev => ({ ...prev, type2: value }));
                }}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              >
                <option value="Indoor">{t('vendor.indoor')}</option>
                <option value="Outdoor">{t('vendor.outdoor')}</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
            >
              {t('common.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {t('vendor.addCourt')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};