import { functions } from "../lib/firebase";
import {  httpsCallable } from "firebase/functions";

export const stripeService = {
	async createConnectAccount() {
		const createConnectAccount = httpsCallable(functions, "createConnectAccount");
		const result = await createConnectAccount();
		return result.data as { url: string };
	},

	async createLoginLink() {
		const createLoginLink = httpsCallable(functions, "createStripeLoginLink");
		const result = await createLoginLink();
		return result.data as { url: string };
	},

	async retriveAccount() {
		const retriveAccount = httpsCallable(functions, "stripeRetriveAccount");
		const result = await retriveAccount();
		return result.data as {
			requirements: { currently_due: string[]; eventually_due: string[]; past_due: string[] };
		};
	},

	async setupPaymentMethod() {
		const createSetupIntent = httpsCallable(functions, "createSetupIntent");
		const result = await createSetupIntent();
		return result.data as { clientSecret: string };
	},

	async savePaymentMethod(paymentMethodId: string) {
		const savePaymentMethod = httpsCallable(functions, "savePaymentMethod");
		return savePaymentMethod({ paymentMethodId });
	},

	async removePaymentMethod(methodId: string) {
		const removePaymentMethod = httpsCallable(functions, "removePaymentMethod");
		return removePaymentMethod({ methodId });
	},

	async createCheckoutSession(params: any) {
		const createCheckoutSession = httpsCallable(functions, "createStripeCheckout");
		const result = await createCheckoutSession(params);
		return result.data as {
			url: string;
		};
	},

	// preAuthorizeBid
	async preAuthorizeBid(params: any) {
		const preAuthorizeBid = httpsCallable(functions, "preAuthorizeBid");
		const result = await preAuthorizeBid(params);
		return result.data as {
			paymentIntentId: string;
		};
	},

	// createBillingPortalLink
	async createBillingPortalLink() {
		const createBillingPortalLink = httpsCallable(functions, "createBillingPortalLink");
		const result = await createBillingPortalLink();
		return result.data as {
			url: string;
		}
	},
};
