import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import { useCourts } from '../../hooks/useCourts';
import { useVendorAuctions } from '../../hooks/useVendorAuctions';
import { useAuth } from '../../context/AuthContext';
import { LoadingSpinner } from '../LoadingSpinner';
import { deleteCourt } from '../../services/court.service';
import { useNotification } from '../../context/NotificationContext';
import { ConfirmationModal } from '../modals/ConfirmationModal';

export const VendorCourtsList: React.FC = () => {
  const { user } = useAuth();
  const { courts, loading, error } = useCourts({ venueId: user?.id });
  const { auctions } = useVendorAuctions();
  const { showNotification } = useNotification();
  const [courtToDelete, setCourtToDelete] = useState<string | null>(null);
  const [activeAuctionError, setActiveAuctionError] = useState<boolean>(false);

  const hasActiveAuctions = (courtId: string) => {
    return auctions.some(auction => auction.courtId === courtId);
  };

  const handleDeleteClick = (courtId: string) => {
    if (hasActiveAuctions(courtId)) {
      setActiveAuctionError(true);
    } else {
      setCourtToDelete(courtId);
    }
  };

  const handleDelete = async (courtId: string) => {
    try {
      await deleteCourt(courtId);
      setCourtToDelete(null);
      showNotification('Court deleted successfully', 'success');
    } catch (error) {
      showNotification('Failed to delete court', 'error');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {courts.map((court) => (
          <div
            key={court.id}
            className="bg-white dark:bg-dark-800 shadow rounded-lg p-6"
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                  {court.name}
                </h4>
                <div className="mt-2 space-x-2">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200">
                    {court.type}
                  </span>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
                    {court.type2}
                  </span>
                </div>
              </div>
              <button
                onClick={() => handleDeleteClick(court.id)}
                className="text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {courtToDelete && (
        <ConfirmationModal
          title="Delete Court"
          message="Are you sure you want to delete this court? This action cannot be undone."
          confirmText="Delete"
          onConfirm={() => handleDelete(courtToDelete)}
          onCancel={() => setCourtToDelete(null)}
        />
      )}

      {/* Active Auction Error Modal */}
      {activeAuctionError && (
        <ConfirmationModal
          title="Cannot Delete Court"
          message="This court has active auctions and cannot be deleted. Please wait for all auctions to complete before deleting the court."
          confirmText="OK"
          showCancel={false}
          onConfirm={() => setActiveAuctionError(false)}
          onCancel={() => setActiveAuctionError(false)}
        />
      )}
    </>
  );
};