// src/components/admin/modals/AddVenueModal.tsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { createVenue } from '../../../services/venue.service';
import { useNotification } from '../../../context/NotificationContext';
import { useAdminCheck } from '../../../hooks/useAdminCheck';

interface AddVenueModalProps {
  onClose: () => void;
  onAdd?: (venue: any) => void;
}

export const AddVenueModal: React.FC<AddVenueModalProps> = ({ onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    description: '',
    openingHours: {
      weekdays: '',
      weekends: ''
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showNotification } = useNotification();
  const { isAdmin, loading: adminCheckLoading } = useAdminCheck();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!isAdmin) {
      showNotification('You must be an administrator to create venues', 'error');
      return;
    }

    console.log('Admin check passed, attempting to create venue');
    setIsSubmitting(true);

    try {
      const result = await createVenue(formData);
      console.log('Venue created successfully:', result);
      showNotification('Venue created successfully. Welcome email sent.', 'success');
      if (onAdd) {
        onAdd(result);
      }
      onClose();
    } catch (error) {
      console.error('Error creating venue:', error);
      let errorMessage = 'Failed to create venue';
      
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      showNotification(errorMessage, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (adminCheckLoading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg p-8 w-full max-w-4xl relative">
        <button
          onClick={onClose}
          className="absolute top-6 right-6 text-gray-400 hover:text-gray-500"
        >
          <X className="h-6 w-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
          Add New Venue
        </h2>

        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
            {/* Basic Information */}
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Venue Name
                </label>
                <input
                  type="text"
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                  placeholder="Enter venue name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                  placeholder="Enter email address"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  required
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            {/* Location Information */}
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Address
                </label>
                <input
                  type="text"
                  required
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                  placeholder="Enter street address"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    City
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                    placeholder="Enter city"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    State
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.state}
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                    placeholder="Enter state"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  ZIP Code
                </label>
                <input
                  type="text"
                  required
                  value={formData.zipCode}
                  onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                  placeholder="Enter ZIP code"
                />
              </div>
            </div>
          </div>

          {/* Description and Hours */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Description
              </label>
              <textarea
                rows={4}
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                placeholder="Enter venue description"
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Weekday Hours
                </label>
                <input
                  type="text"
                  required
                  value={formData.openingHours.weekdays}
                  onChange={(e) => setFormData({
                    ...formData,
                    openingHours: { ...formData.openingHours, weekdays: e.target.value }
                  })}
                  placeholder="e.g., 9:00 AM - 6:00 PM"
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Weekend Hours
                </label>
                <input
                  type="text"
                  required
                  value={formData.openingHours.weekends}
                  onChange={(e) => setFormData({
                    ...formData,
                    openingHours: { ...formData.openingHours, weekends: e.target.value }
                  })}
                  placeholder="e.g., 10:00 AM - 4:00 PM"
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-dark-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-900 text-gray-900 dark:text-white placeholder-gray-400"
                />
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-6 py-3 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Creating...' : 'Create Venue'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
