import React, { createContext, useContext, useState } from 'react';

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: string, params?: Record<string, string>) => string;
}

const translations = {
  en: {
    'nav.courts': 'Courts',
    'nav.myBids': 'My Bids',
    'nav.billing': 'Billing',
    'nav.general': 'General',
    'nav.settings': 'Settings',
    'nav.signOut': 'Sign Out',
    'common.today': 'Today',
    'common.tomorrow': 'Tomorrow',
    'common.search': 'Search',
    'common.cancel': 'Cancel',
    'common.save': 'Save',
    'common.close': 'Close',
    'home.welcome': 'Welcome to Bidcourt',
    'home.subtitle': 'Bid on your favorite sports courts and secure your play time.',
    'home.searchPlaceholder': 'Search by venue, location or court name...',
    'auth.required': 'Authentication Required',
    'auth.prompt': 'Please sign in or create an account to continue',
    'auth.createAccount': 'Create Account',
    'auth.signIn': 'Sign In',
    'profile.accountSettings': 'Account Settings',
    'bid.placeBid': 'Place Bid',
    'bid.currentBid': 'Current Bid',
    'bid.yourBid': 'Your Bid',
    'bid.bidAmount': 'Bid Amount',
    'auction.notFound': 'Auction not found',
    'auction.about': 'About',
    'auction.contact': 'Contact Information',
    'auction.description': 'Description',
    'auction.location': 'Location',
    'auction.phone': 'Phone',
    'auction.email': 'Email',
    'auction.website': 'Website',
    'auction.hours': 'Opening Hours',
    'auction.weekdays': 'Weekdays',
    'auction.weekends': 'Weekends',
    'bid.bids': 'bids',
    'vendor.greeting': 'Hi, {name}',
    'vendor.subtitle': 'Manage your courts and auctions',
    'vendor.addAuction': 'Add New Auction',
    'vendor.addCourt': 'Add New Court',
    'vendor.courtAdded': 'Court added successfully',
    'vendor.auctionAdded': 'Auction added successfully',
    'vendor.deleteAuction': 'Delete Auction',
    'vendor.deleteAuctionConfirm': 'Are you sure you want to delete this auction? This action cannot be undone.',
    'vendor.auctionDeleted': 'Auction deleted successfully',
    'vendor.auctionDetails': 'Auction Details',
    'vendor.totalBids': 'Total Bids',
    'vendor.startingPrice': 'Starting Price',
    'vendor.currentHighest': 'Current Highest',
    'vendor.totalIncrease': 'Total Increase',
    'vendor.bidHistory': 'Bid History',
    'vendor.addNewCourt': 'Add New Court',
    'vendor.courtName': 'Court Name',
    'vendor.courtType': 'Court Type',
    'vendor.location': 'Location',
    'vendor.indoor': 'Indoor',
    'vendor.outdoor': 'Outdoor',
    'vendor.stripeRequired': 'Stripe Connect Required',
    'vendor.stripeMessage': 'Before you can create auctions, you need to connect your Stripe account to receive payments. This is a one-time setup that allows you to receive payouts from your auctions.',
    'vendor.setupStripe': 'Set Up Stripe',
    'vendor.venueProfile': 'Venue Profile',
    'vendor.updateVenue': 'Update your venue information and settings.',
    'vendor.profileImage': 'Profile Image',
    'vendor.venueImages': 'Venue Images',
    'vendor.addImages': 'Add Images',
    'vendor.venueName': 'Venue Name',
    'vendor.website': 'Website',
    'vendor.email': 'Email',
    'vendor.phone': 'Phone',
    'vendor.address': 'Address',
    'vendor.openingHours': 'Opening Hours',
    'vendor.weekdayHours': 'Weekday Hours',
    'vendor.weekendHours': 'Weekend Hours',
    'vendor.description': 'Description',
    'vendor.saveChanges': 'Save Changes',
    'vendor.participants': 'participants',
    'vendor.unnamedCourt': 'Unnamed Court',
    'vendor.unnamedVenue': 'Unnamed Venue',
    'vendor.viewDetails': 'Auction Details',
    'vendor.activeAuctions': "Active Auctions",
    'vendor.yourCourts': "My Courts",
    'venue.contactInfo': "Contact Information",
    'venue.activeAuctions': "Active Auctions",
    'venue.openingHours': "Opening Hours",
    'bid.amount': "Bid Amount",
    'bid.minimumBid': "Minimum Bid",
    'bid.confirm': "Confirm",
    'login.title': 'Sign in to Bidcourt',
    'login.createAccount': 'Or create a new account',
    'login.email': 'Email address',
    'login.password': 'Password',
    'login.rememberMe': 'Remember me',
    'login.forgotPassword': 'Forgot password?',
    'login.signIn': 'Sign in',
    'login.signingIn': 'Signing in...',
    'login.continueWith': 'Or continue with',
    'login.google': 'Google',
    'login.apple': 'Apple',
    'signup.title': 'Create your account',
    'signup.haveAccount': 'Already have an account?',
    'signup.login': 'Sign in',
    'signup.firstName': 'First Name',
    'signup.lastName': 'Last Name',
    'signup.email': 'Email address',
    'signup.password': 'Password',
    'signup.register': 'Sign up',
    'signup.registering': 'Signing up...',
    'nav.legal': 'Legal',
    'footer.privacy': 'Privacy Policy',
    'footer.terms': 'Terms of Service',
    'footer.cookies': 'Cookie Policy',
    'footer.rights': 'All rights reserved.',
    'myBids.title': 'My Bids',
    'myBids.subtitle': 'Track and manage your active bids',
    'myBids.yourBid': 'Your Bid',
    'myBids.currentHighest': 'Current Highest',
    'myBids.leading': 'Leading',
    'myBids.increaseBid': 'Increase Bid',
    'myBids.noBids': 'No active bids found',
    'auction.timeLeft': 'Time Left',
    'home.noAuctions': 'No auctions found',
    'emailVerification.title': 'Check your email',
    'emailVerification.subtitle': 'We\'ve sent you an email with a verification link. Please click the link to verify your account.',
    'emailVerification.checkSpam': 'Didn\'t receive the email? Check your spam folder or',
    'emailVerification.resend': 'Click here to resend',
    'emailVerification.returnToLogin': 'Return to login',
    'emailVerification.verificationFailed': 'Verification Failed',
    'emailVerification.emailVerified': 'Email Verified',
    'emailVerification.redirecting': 'Your email has been successfully verified. Redirecting to dashboard...',
    'auction.addNew': 'Add New Auction',
    'auction.type': 'Auction Type',
    'auction.single': 'Single Auction',
    'auction.recurring': 'Recurring Auction',
    'auction.selectCourt': 'Select Court',
    'auction.date': 'Date',
    'auction.startTime': 'Start Time',
    'auction.endTime': 'End Time',
    'auction.basePrice': 'Base Price ($)',
    'auction.cancel': 'Cancel',
    'auction.create': 'Create Auction',
    'common.back': 'Go back',
    'bid.participants': 'participants',
    'venue.readMore': 'Read more',
    'venue.showLess': 'Show less',
    'venue.contactInfo': 'Contact Information',
    'venue.openingHours': 'Opening Hours',
    'venue.viewAuction': 'Auction Details',
    'venue.indoor': 'Indoor',
    'venue.outdoor': 'Outdoor',
    'vendor.noActiveAuctions': 'No active auctions',
    'auction.untilDate': 'Until Date',
    'vendor.closedAuctions': 'Closed Auctions',
    'vendor.noClosedAuctions': 'No closed auctions yet',
    'bid.paymentMethod': 'Payment Method',
    'vendor.payoutSettings': 'Payout Settings',
    'vendor.payoutSettingsDescription': 'Configure payout settings for your auctions.',
    'vendor.stripeConnected': 'Stripe Connected',
    'vendor.managePayoutDescription': 'Manage your payments and payout settings.',
    'vendor.managePayouts': 'Manage Payouts',
    'bid.minimumBid': 'Minimum Bid',
    'bid.minimumIncrement': 'Minimum Increment',
    'bid.confirmationTitle': 'Confirm Bid',
    'bid.confirmationDescription': 'As a reminder, all bids are binding. If you\'re the highest bidder, you agree to pay for this court.',
    'bid.proceedToPayment': 'Proceed to Payment',
    'bid.confirmBid': 'Confirm Bid',
    'bid.payment': 'Payment Method',
    'bid.paymentTitle': 'Payment',
    'bid.paymentDescription': 'Select the payment method for your bid.',
    'bid.preAuthSuccess': 'Pre-authorization Successful',
    'bid.preAuthFailed': 'Pre-authorization Failed. Please try again.',
    'billing.paymentMethods': 'Payment Methods',
    'billing.managePaymentMethods': 'Add and manage your payment methods for bidding.',
    'billing.managePaymentMethodsLink': 'Manage Payment Methods',
    'billing.addPaymentMethod': 'Add Payment Method',
    'billing.noPaymentMethods': 'No payment methods added',
    'search.searchByCourtOrVenue': 'Search by venue, or court name...',
    'billing.paymentMethodAdded': 'Payment method added successfully',
    'billing.paymentMethodError': 'Payment method error',
    'billing.paymentMethodRemoved': 'Payment method removed',
    'billing.paymentMethodRemoveError': 'Payment method remove error',
    'common.processing': 'Processing...'
  },
  it: {
    'nav.courts': 'Campi',
    'nav.myBids': 'Le Mie Offerte',
    'nav.billing': 'Fatturazione',
    'nav.general': 'Generale',
    'nav.settings': 'Impostazioni',
    'nav.signOut': 'Esci',
    'common.today': 'Oggi',
    'common.tomorrow': 'Domani',
    'common.search': 'Cerca',
    'common.cancel': 'Annulla',
    'common.save': 'Salva',
    'common.close': 'Chiudi',
    'home.welcome': 'Benvenuto su Bidcourt',
    'home.subtitle': 'Fai offerte sui tuoi campi sportivi preferiti e assicurati il tuo tempo di gioco.',
    'home.searchPlaceholder': 'Cerca per struttura, località o nome del campo...',
    'auth.required': 'Autenticazione Richiesta',
    'auth.prompt': 'Accedi o crea un account per continuare',
    'auth.createAccount': 'Crea Account',
    'auth.signIn': 'Accedi',
    'profile.accountSettings': 'Impostazioni Account',
    'bid.placeBid': 'Fai Offerta',
    'bid.currentBid': 'Offerta Attuale',
    'bid.yourBid': 'La Tua Offerta',
    'bid.bidAmount': 'Importo Offerta',
    'auction.notFound': 'Asta non trovata',
    'auction.about': 'Informazioni',
    'auction.contact': 'Contatti',
    'auction.description': 'Descrizione',
    'auction.location': 'Posizione',
    'auction.phone': 'Telefono',
    'auction.email': 'Email',
    'auction.website': 'Sito web',
    'auction.hours': 'Orari di apertura',
    'auction.weekdays': 'Giorni feriali',
    'auction.weekends': 'Fine settimana',
    'bid.bids': 'offerte',
    'vendor.greeting': 'Ciao, {name}',
    'vendor.subtitle': 'Gestisci i tuoi campi e le aste',
    'vendor.addAuction': 'Aggiungi Nuova Asta',
    'vendor.addCourt': 'Aggiungi Nuovo Campo',
    'vendor.courtAdded': 'Campo aggiunto con successo',
    'vendor.auctionAdded': 'Asta aggiunta con successo',
    'vendor.deleteAuction': 'Elimina Asta',
    'vendor.deleteAuctionConfirm': 'Sei sicuro di voler eliminare questa asta? Questa azione non può essere annullata.',
    'vendor.auctionDeleted': 'Asta eliminata con successo',
    'vendor.auctionDetails': 'Dettagli Asta',
    'vendor.totalBids': 'Offerte Totali',
    'vendor.startingPrice': 'Prezzo Iniziale',
    'vendor.currentHighest': 'Offerta Più Alta',
    'vendor.totalIncrease': 'Aumento Totale',
    'vendor.bidHistory': 'Cronologia Offerte',
    'vendor.addNewCourt': 'Aggiungi Nuovo Campo',
    'vendor.courtName': 'Nome Campo',
    'vendor.courtType': 'Tipo di Campo',
    'vendor.location': 'Posizione',
    'vendor.indoor': 'Indoor',
    'vendor.outdoor': 'Outdoor',
    'vendor.stripeRequired': 'Stripe Connect Richiesto',
    'vendor.stripeMessage': 'Prima di poter creare aste, devi collegare il tuo account Stripe per ricevere i pagamenti. Questa è una configurazione una tantum che ti permette di ricevere pagamenti dalle tue aste.',
    'vendor.setupStripe': 'Configura Stripe',
    'vendor.venueProfile': 'Profilo Struttura',
    'vendor.updateVenue': 'Aggiorna le informazioni e le impostazioni della tua struttura.',
    'vendor.profileImage': 'Immagine Profilo',
    'vendor.venueImages': 'Immagini Struttura',
    'vendor.addImages': 'Aggiungi Immagini',
    'vendor.venueName': 'Nome Struttura',
    'vendor.website': 'Sito Web',
    'vendor.email': 'Email',
    'vendor.phone': 'Telefono',
    'vendor.address': 'Indirizzo',
    'vendor.openingHours': 'Orari di Apertura',
    'vendor.weekdayHours': 'Orari Feriali',
    'vendor.weekendHours': 'Orari Weekend',
    'vendor.description': 'Descrizione',
    'vendor.saveChanges': 'Salva Modifiche',
    'vendor.participants': 'partecipanti',
    'vendor.unnamedCourt': 'Campo Senza Nome',
    'vendor.unnamedVenue': 'Struttura Senza Nome',
    'vendor.viewDetails': 'Dettagli ',
    'vendor.activeAuctions': "Aste Attive",
    'vendor.yourCourts': "Campi Attivi",
    'venue.contactInfo': "Contatti",
    'venue.activeAuctions': "Aste Attive",
    'venue.openingHours': "Orari",
    'bid.amount': "Offerta",
    'bid.minimumBid': "Offerta Minima",
    'bid.confirm': "Conferma",
    'login.title': 'Accedi a Bidcourt',
    'login.createAccount': 'O crea un nuovo account',
    'login.email': 'Indirizzo email',
    'login.password': 'Password',
    'login.rememberMe': 'Ricordami',
    'login.forgotPassword': 'Password dimenticata?',
    'login.signIn': 'Accedi',
    'login.signingIn': 'Accesso in corso...',
    'login.continueWith': 'Oppure continua con',
    'login.google': 'Google',
    'login.apple': 'Apple',
    'signup.title': 'Crea il tuo account',
    'signup.haveAccount': 'Hai già un account?',
    'signup.login': 'Accedi',
    'signup.firstName': 'Nome',
    'signup.lastName': 'Cognome',
    'signup.email': 'Indirizzo email',
    'signup.password': 'Password',
    'signup.register': 'Registrati',
    'signup.registering': 'Registrazione in corso...',
    'nav.legal': 'Legale',
    'footer.privacy': 'Informativa sulla Privacy',
    'footer.terms': 'Termini di Servizio',
    'footer.cookies': 'Politica dei Cookie',
    'footer.rights': 'Tutti i diritti riservati.',
    'myBids.title': 'Le Mie Offerte',
    'myBids.subtitle': 'Monitora e gestisci le tue offerte attive',
    'myBids.yourBid': 'La Tua Offerta',
    'myBids.currentHighest': 'Offerta Più Alta',
    'myBids.leading': 'In Testa',
    'myBids.increaseBid': 'Aumenta Offerta',
    'myBids.noBids': 'Nessuna offerta attiva trovata',
    'auction.timeLeft': 'Tempo Rimanente',
    'home.noAuctions': 'Nessuna asta trovata',
    'emailVerification.title': 'Controlla la tua email',
    'emailVerification.subtitle': 'Ti abbiamo inviato un\'email con un link di verifica. Clicca sul link per verificare il tuo account.',
    'emailVerification.checkSpam': 'Non hai ricevuto l\'email? Controlla la cartella spam oppure',
    'emailVerification.resend': 'Clicca qui per reinviare',
    'emailVerification.returnToLogin': 'Torna al login',
    'emailVerification.verificationFailed': 'Verifica Fallita',
    'emailVerification.emailVerified': 'Email Verificata',
    'emailVerification.redirecting': 'La tua email è stata verificata con successo. Reindirizzamento alla dashboard...',
    'auction.addNew': 'Aggiungi Nuova Asta',
    'auction.type': 'Tipo di Asta',
    'auction.single': 'Asta Singola',
    'auction.recurring': 'Asta Ricorrente',
    'auction.selectCourt': 'Seleziona Campo',
    'auction.date': 'Data',
    'auction.startTime': 'Ora Inizio',
    'auction.endTime': 'Ora Fine',
    'auction.basePrice': 'Prezzo Base (€)',
    'auction.cancel': 'Annulla',
    'auction.create': 'Crea Asta',
    'common.back': 'Indietro',
    'bid.participants': 'partecipanti',
    'venue.readMore': 'Leggi di più',
    'venue.showLess': 'Mostra meno',
    'venue.contactInfo': 'Contatti',
    'venue.openingHours': 'Orari di Apertura',
    'venue.viewAuction': 'Dettagli',
    'venue.indoor': 'Indoor',
    'venue.outdoor': 'Outdoor',
    'vendor.noActiveAuctions': 'Nessuna asta attiva attiva',
    'auction.untilDate': 'Fino alla Data',
    'vendor.closedAuctions': 'Aste Chiuse',
    'noClosedAuctions': 'Nessuna asta chiusa',
    'vendor.noClosedAuctions': 'Nessuna asta chiusa',
    'bid.paymentMethod': 'Metodo di Pagamento',
    'vendor.payoutSettings': 'Impostazioni Pagamenti',
    'vendor.payoutSettingsDescription': 'Configura le impostazioni per i tuoi pagamenti.',
    'vendor.stripeConnected': 'Stripe Connesso',
    'vendor.managePayoutDescription': 'Gestisci i tuoi pagamenti e le impostazioni di pagamento.',
    'vendor.managePayouts': 'Gestisci Pagamenti',
    'bid.minimumBid': 'Offerta Minima',
    'bid.minimumIncrement': 'Incremento Minimo',
    'bid.confirmationTitle': 'Conferma Offerta',
    'bid.confirmationDescription': 'Come promemoria, tutte le offerte sono vincolanti. Se sei il più alto offerente, accetti di pagare per questo campo.',
    'bid.proceedToPayment': 'Conferma e Paga',
    'bid.confirmBid': 'Conferma Offerta',
    'bid.payment': 'Metodo di Pagamento',
    'bid.paymentTitle': 'Pagamento',
    'bid.paymentDescription': 'Seleziona il metodo di pagamento',
    'bid.preAuthSuccess': 'Pre-autorizzazione Riuscita',
    'bid.preAuthFailed': 'Pre-autorizzazione Fallita. Riprova.',
    'billing.paymentMethods': 'Metodi di Pagamento',
    'billing.managePaymentMethods': 'Aggiungi e gestisci i tuoi metodi di pagamento per le offerte.',
    'billing.addPaymentMethod': 'Aggiungi Metodo di Pagamento',
    'billing.noPaymentMethods': 'Nessun metodo di pagamento aggiunto',
    'search.searchByCourtOrVenue': 'Cerca per struttura o nome del campo...',
    'billing.paymentMethodAdded': 'Metodo di pagamento aggiunto con successo',
    'billing.paymentMethodError': 'Errore nell\'aggiunta del metodo di pagamento',
    'billing.paymentMethodRemoved': 'Metodo di pagamento rimosso',
    'billing.paymentMethodRemoveError': 'Errore nella rimozione del metodo di pagamento',
    'common.processing': 'Elaborazione in corso...'
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const t = (key: string, params?: Record<string, string>): string => {
    let translation = translations[language as keyof typeof translations]?.[key as keyof typeof translations['en']] || key;
    
    if (params) {
      Object.entries(params).forEach(([param, value]) => {
        translation = translation.replace(`{${param}}`, value);
      });
    }
    
    return translation;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};