import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, DocumentData } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';
import type { Auction } from '../types';

export const useVendorAuctions = (options: { status?: 'active' | 'closed' } = {}) => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { user } = useAuth();

  useEffect(() => {
    if (!user?.id) {
      setError('No user ID found');
      setLoading(false);
      return;
    }

    const auctionsRef = collection(db, 'auctions');
    const auctionsQuery = query(
      auctionsRef,
      where('venueId', '==', user.id),
      where('status', '==', 'active')
    );

    const unsubscribe = onSnapshot(
      auctionsQuery,
      async (snapshot) => {
        try {
          const auctionsData = await Promise.all(
            snapshot.docs.map(async (auctionDoc) => {
              const data = auctionDoc.data();
              
              // Fetch court data
              const courtRef = doc(db, 'courts', data.courtId);
              const courtSnap = await getDoc(courtRef);
              const courtData = courtSnap.data();

              // Fetch venue data
              const venueRef = doc(db, 'venues', data.venueId);
              const venueSnap = await getDoc(venueRef);
              const venueData = venueSnap.data();

              return {
                id: auctionDoc.id,
                courtId: data.courtId,
                date: data.date,
                startTime: data.startTime,
                endTime: data.endTime,
                basePrice: data.basePrice,
                currentBid: data.currentBid,
                participants: data.participants || 0,
                status: data.status,
                createdAt: data.createdAt,
                court: {
                  id: data.courtId,
                  name: courtData?.name || 'Unknown Court',
                  type: courtData?.type || 'Unknown Type',
                  type2: courtData?.type2 || 'Outdoor',
                  image: courtData?.image,
                  venue: {
                    id: data.venueId,
                    name: venueData?.name || 'Unknown Venue',
                    profileImage: venueData?.profileImage
                  }
                },
                auctionEndTime: data.auctionEndTime,
              } as Auction;
            })
          );

          // Filter auctions based on their end time
          const now = new Date();
          const filteredAuctions = auctionsData.filter(auction => {
            const isExpired = new Date(auction.auctionEndTime) <= now;
            return options.status === 'closed' ? isExpired : !isExpired;
          });

          // Sort by end time (closest to ending first)
          const sortedAuctions = filteredAuctions.sort((a, b) => {
            const aEndTime = new Date(a.auctionEndTime).getTime();
            const bEndTime = new Date(b.auctionEndTime).getTime();
            return aEndTime - bEndTime;
          });

          setAuctions(sortedAuctions);
          setLoading(false);
        } catch (error) {
          console.error('Error processing auctions:', error);
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [user?.id, options.status]);

  return { auctions, loading, error };
};
