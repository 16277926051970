import { collection, type CollectionReference } from 'firebase/firestore';
import { db } from './firebase';
import type { User, Venue, Court, Auction, Bid, PaymentDistribution, PaymentMethod, StripeConnect } from '../types';

// Collections
export const usersCollection = collection(db, 'users') as CollectionReference<User>;
export const venuesCollection = collection(db, 'venues') as CollectionReference<Venue>;
export const courtsCollection = collection(db, 'courts') as CollectionReference<Court>;
export const auctionsCollection = collection(db, 'auctions') as CollectionReference<Auction>;
export const bidsCollection = collection(db, 'bids') as CollectionReference<Bid>;
export const paymentMethodsCollection = collection(db, 'paymentMethods') as CollectionReference<PaymentMethod>;
export const paymentDistributionCollection = collection(db, 'paymentDistribution') as CollectionReference<PaymentDistribution>;
export const stripeConnectCollection = collection(db, 'stripeConnect') as CollectionReference<StripeConnect>;

export { db };