import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, functions } from '../lib/firebase';
import { getCurrentUser, registerUser, loginUser, logoutUser } from '../services/auth.service';
import type { User } from '../types';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db } from '../lib/firebase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Force refresh the Firebase user to get latest emailVerified status
          await firebaseUser.reload();
          
          // Get user data from Firestore
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          const userData = userDoc.data();
          
          if (userData) {
            // Update user state with latest data
            setUser({
              ...userData,
              id: firebaseUser.uid,
              emailVerified: firebaseUser.emailVerified
            } as User);

            // If this is their first login (after email verification)
            if (!userData.welcomeEmailSent && firebaseUser.emailVerified) {
              const sendEmail = httpsCallable(functions, 'sendEmail');
              
              try {
                await sendEmail({
                  templateId: 'user-welcome',
                  to: firebaseUser.email,
                  data: {
                    firstName: userData.firstName || 'User'
                  }
                });
                
                // Mark welcome email as sent
                await updateDoc(doc(db, 'users', firebaseUser.uid), {
                  welcomeEmailSent: true
                });
              } catch (error) {
                console.error('Error sending welcome email:', error);
              }
            }
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const { user } = await registerUser(email, password, firstName, lastName);
    setUser(user);
  };

  const login = async (email: string, password: string) => {
    await loginUser(email, password);
    // Don't setUser here - onAuthStateChanged will handle it
  };

  const logout = async () => {
    await logoutUser();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, register, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};