import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { VendorDashboard } from '../../pages/vendor/VendorDashboard';
import { ChangePasswordPrompt } from './ChangePasswordPrompt';
import { LoadingSpinner } from '../LoadingSpinner';

export const VendorDashboardWrapper: React.FC = () => {
  const [needsPasswordChange, setNeedsPasswordChange] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPasswordStatus = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          navigate('/login');
          return;
        }

        const userData = userDoc.data();
        setNeedsPasswordChange(!userData.hasChangedPassword);
      } catch (error) {
        console.error('Error checking password status:', error);
        navigate('/login');
      }
    };

    checkPasswordStatus();
  }, [navigate]);

  const handlePasswordChangeComplete = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      // Update the user document to mark password as changed
      await updateDoc(doc(db, 'users', user.uid), {
        hasChangedPassword: true
      });

      setNeedsPasswordChange(false);
    } catch (error) {
      console.error('Error updating password status:', error);
    }
  };

  if (needsPasswordChange === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {needsPasswordChange && (
        <ChangePasswordPrompt onComplete={handlePasswordChangeComplete} />
      )}
      <VendorDashboard />
    </>
  );
}; 