import React, { useState } from "react";
import { X, AlertTriangle, CheckCircle, XCircle, CreditCard, Plus } from "lucide-react";
import { useLanguage } from "../../context/LanguageContext";
import { useBidding } from "../../hooks/useBidding";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, PaymentRequestButtonElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { LoadingSpinner } from "../LoadingSpinner";
import { useStripeCheckout } from "../../hooks/useStripeCheckout";
import { useAuth } from "../../context/AuthContext";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

enum BidPhase {
	INITIAL = "initial",
	CONFIRMATION = "confirmation",
	PAYMENT = "payment",
}

interface BidModalProps {
	auction: {
		id: string;
		courtName: string;
		venueName: string;
		currentBid: number;
		basePrice: number;
		date: string;
		startTime: string;
		endTime: string;
		image?: string;
	};
	onClose: () => void;
}

interface PreAuthStatus {
	status: "success" | "error";
	message: string;
}

// Add this new PaymentForm component before the BidModalContent
const PaymentForm: React.FC<{ onSubmit: (paymentMethodId: string) => void; loading: boolean }> = ({ onSubmit, loading }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<string | null>(null);
	const { t } = useLanguage();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!stripe || !elements) return;

		setError(null);

		try {
			const cardElement = elements.getElement(CardElement);
			if (!cardElement) throw new Error("Card element not found");

			const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
			});

			if (stripeError) {
				throw new Error(stripeError.message);
			}

			if (paymentMethod) {
				onSubmit(paymentMethod.id);
			}
		} catch (err) {
			setError(err instanceof Error ? err.message : "Failed to process payment");
		}
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4">
			<div className="p-4 border rounded-lg">
				<CardElement
					options={{
						style: {
							base: {
								fontSize: "16px",
								color: "#424770",
								"::placeholder": {
									color: "#aab7c4",
								},
							},
							invalid: {
								color: "#9e2146",
							},
						},
					}}
				/>
			</div>

			{error && <div className="text-sm text-red-600 dark:text-red-400">{error}</div>}

			<button 
				type="submit" 
				disabled={!stripe || loading} 
				className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
			>
				{loading ? <LoadingSpinner size="small" /> : t("bid.confirmPayment")}
			</button>
		</form>
	);
};

// Add this new component for displaying saved payment methods
const SavedPaymentMethods: React.FC<{
	onSelect: (paymentMethodId: string) => void;
	selectedId?: string;
}> = ({ onSelect, selectedId }) => {
	const { paymentMethods, isLoading } = usePaymentMethods();
	const { t } = useLanguage();

	if (isLoading) return <LoadingSpinner />;

	return (
		<div className="space-y-3">
			{paymentMethods.map((method) => (
				<div
					key={method.id}
					className={`p-3 border rounded-lg cursor-pointer flex items-center space-x-3 ${
						selectedId === method.id ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20' : 'hover:bg-gray-50 dark:hover:bg-dark-700'
					}`}
					onClick={() => onSelect(method.id)}
				>
					<CreditCard className="h-5 w-5 text-gray-400" />
					<div>
						<p className="text-sm font-medium text-gray-900 dark:text-white">
							•••• {method.card.last4}
						</p>
						<p className="text-xs text-gray-500">
							{method.card.brand} - {t("payment.expires")} {method.card.exp_month}/{method.card.exp_year}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

// Create a separate component for the modal content
const BidModalContent: React.FC<BidModalProps & { onClose: () => void }> = ({ auction, onClose }) => {
	const { t } = useLanguage();
	const { user } = useAuth();

	const [bidAmount, setBidAmount] = useState<number>(auction.currentBid ? auction.currentBid + 5 : auction.basePrice);
	const [phase, setPhase] = useState<BidPhase>(BidPhase.INITIAL);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [preAuthStatus, setPreAuthStatus] = useState<PreAuthStatus | null>(null);
	const { createCheckoutSession } = useStripeCheckout();
	const { submitBid, isSubmitting } = useBidding(auction.id);
	const [showNewCard, setShowNewCard] = useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();
	const handleInitialConfirm = () => {
		if (bidAmount < auction.basePrice) {
			setError(t("bid.errorBelowBase"));
			return;
		}

		const increment = bidAmount - (auction.currentBid || auction.basePrice);
		if (increment <= 0 || increment % 5 !== 0) {
			setError(t("bid.errorInvalidIncrement"));
			return;
		}

		setError(null);
		setPhase(BidPhase.CONFIRMATION);
	};

	const handleOnSuccessBidding = () => {
		setPreAuthStatus({
			status: 'success',
			message: t('bid.redirectingToPayment')
		});
		// wait 2sec before closing
		setTimeout(() => {
			onClose();
		}, 2000);
	};

	const handleFinalConfirm = () => {
		if (!user) {
			setPreAuthStatus({
				status: "error",
				message: t("bid.errorNotLoggedIn"),
			});
			return;
		}

		setPhase(BidPhase.PAYMENT);
	};

	const renderPhase = () => {
		switch (phase) {
			case BidPhase.INITIAL:
				return (
					<div className="space-y-4">
						<div className="bg-gray-50 dark:bg-dark-700 p-4 rounded-lg">
							<h3 className="font-medium text-gray-900 dark:text-white mb-2">{auction.courtName}</h3>
							<p className="text-sm text-gray-500 dark:text-gray-400 mb-4">{auction.venueName}</p>

							<div className="grid grid-cols-2 gap-4 text-sm mb-4">
								<div>
									<p className="text-gray-500 dark:text-gray-400">Data</p>
									<p className="font-medium text-gray-900 dark:text-white">{auction.date}</p>
								</div>
								<div>
									<p className="text-gray-500 dark:text-gray-400">Ora Inizio</p>
									<p className="font-medium text-gray-900 dark:text-white">
										{auction.startTime} - {auction.endTime}
									</p>
								</div>
							</div>

							<div className="grid grid-cols-2 gap-4 text-sm">
								<div>
									<p className="text-gray-500 dark:text-gray-400">{t("bid.currentBid")}</p>
									<p className="font-medium text-gray-900 dark:text-white">€{auction.currentBid}</p>
								</div>
								<div>
									<p className="text-gray-500 dark:text-gray-400">{t("bid.minimumBid")}</p>
									<p className="font-medium text-gray-900 dark:text-white">€{auction.currentBid + 5}</p>
								</div>
							</div>
						</div>

						<div>
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t("bid.amount")}</label>
							<div className="mt-1">
								<input type="number" min={auction.currentBid + 5} step="5" value={bidAmount} onChange={(e) => setBidAmount(Number(e.target.value))} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
							</div>
						</div>

						<button onClick={handleInitialConfirm} className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700">
							{t("bid.confirm")}
						</button>
					</div>
				);

			case BidPhase.CONFIRMATION:
				return (
					<div className="space-y-6 text-center">
						<AlertTriangle className="mx-auto h-12 w-12 text-yellow-500" />
						<div>
							<h3 className="text-lg font-medium text-gray-900 dark:text-white">{t("bid.confirmationTitle")}</h3>
							<p className="mt-2 text-sm text-gray-500">{t("bid.confirmationDescription")}</p>
							<div className="mt-4 p-4 bg-gray-50 dark:bg-dark-700 rounded-md">
								<p className="text-sm font-medium text-gray-900 dark:text-white">
									{t("bid.amount")}: €{bidAmount}
								</p>
							</div>
						</div>

						<div className="flex space-x-3">
							<button onClick={() => setPhase(BidPhase.INITIAL)} className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
								{t("common.back")}
							</button>
							<button
								onClick={handleFinalConfirm}
								// onClick={() => setPhase(BidPhase.PAYMENT)}
								className="flex-1 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
							>
								{t("bid.proceedToPayment")}
							</button>
						</div>
					</div>
				);
			

			case BidPhase.PAYMENT:
				return (
					<div className="space-y-4">
						<div>
							<h3 className="text-lg font-medium text-gray-900 dark:text-white">
								{t("bid.paymentTitle")}
							</h3>
							<p className="mt-2 text-sm text-gray-500">
								{t("bid.paymentDescription")}
							</p>
							<div className="mt-4 p-4 bg-gray-50 dark:bg-dark-700 rounded-md">
								<p className="text-sm font-medium text-gray-900 dark:text-white">
									{t("bid.amount")}: €{bidAmount}
								</p>
							</div>
						</div>

						{!showNewCard ? (
							<>
								<SavedPaymentMethods
									onSelect={setSelectedPaymentMethod}
									selectedId={selectedPaymentMethod}
								/>

								<button
									onClick={() => setShowNewCard(true)}
									className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 rounded-md hover:bg-indigo-50 dark:hover:bg-indigo-900/20"
								>
									<Plus className="h-4 w-4 mr-2" />
									{t("payment.addNewCard")}
								</button>

								{selectedPaymentMethod && (
									<button
										onClick={() => submitBid(bidAmount, selectedPaymentMethod,handleOnSuccessBidding)}
										disabled={isSubmitting}
										className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
									>
										{isSubmitting ? (
											<LoadingSpinner size="small" />
										) : (
											t("bid.confirmPayment")
										)}
									</button>
								)}
							</>
						) : (
							<>
								<PaymentForm
									onSubmit={async (paymentMethodId) => {
										try {
											await submitBid(bidAmount, paymentMethodId,handleOnSuccessBidding);
											setPreAuthStatus({
												status: "success",
												message: t("bid.success"),
											});
											setTimeout(onClose, 2000);
										} catch (err) {
											setPreAuthStatus({
												status: "error",
												message: err instanceof Error ? err.message : "Failed to place bid",
											});
										}
									}}
									loading={isSubmitting}
								/>

								<button
									onClick={() => setShowNewCard(false)}
									className="w-full px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
								>
									{t("payment.backToSaved")}
								</button>
							</>
						)}
					</div>
				);
		}
	};

	const renderPreAuthStatus = () => {
		if (!preAuthStatus) return null;

		return (
			<div className="absolute inset-0 bg-white dark:bg-dark-800 bg-opacity-95 dark:bg-opacity-95 flex items-center justify-center z-50">
				<div className="text-center p-6">
					{preAuthStatus.status === "success" ? <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" /> : <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />}
					<p className={`text-lg font-medium ${preAuthStatus.status === "success" ? "text-green-600 dark:text-green-400" : "text-red-600 dark:text-red-400"}`}>{preAuthStatus.message}</p>
				</div>
			</div>
		);
	};

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
			<div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-md relative">
				{renderPreAuthStatus()}
				<div className="flex justify-between items-center p-4 border-b dark:border-dark-700">
					<h2 className="text-lg font-medium text-gray-900 dark:text-white">
						{phase === BidPhase.INITIAL && t("bid.placeBid")}
						{phase === BidPhase.CONFIRMATION && t("bid.confirmBid")}
						{phase === BidPhase.PAYMENT && t("bid.payment")}
					</h2>
					<button onClick={onClose} className="text-gray-400 hover:text-gray-500">
						<X className="h-5 w-5" />
					</button>
				</div>

				<div className="p-4">
					{error && <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/50 text-red-700 dark:text-red-200 rounded-md text-sm">{error}</div>}
					{renderPhase()}
				</div>
			</div>
		</div>
	);
};

// Main BidModal component that wraps the content with Elements
export const BidModal: React.FC<BidModalProps> = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<BidModalContent {...props} />
		</Elements>
	);
};
