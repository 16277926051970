import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';
import { resendVerificationEmail } from '../services/auth.service';
import { useNotification } from '../context/NotificationContext';
import { useLanguage } from '../context/LanguageContext';

export const EmailVerification: React.FC = () => {
  const [resending, setResending] = useState(false);
  const { showNotification } = useNotification();
  const { t } = useLanguage();

  const handleResend = async () => {
    try {
      setResending(true);
      await resendVerificationEmail();
      showNotification('Email di verifica inviata con successo', 'success');
    } catch (error) {
      showNotification(
        error instanceof Error ? error.message : 'Impossibile inviare l\'email di verifica',
        'error'
      );
    } finally {
      setResending(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-blue-50 dark:from-dark-900 dark:to-dark-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white dark:bg-dark-800 p-8 rounded-xl shadow-lg text-center">
        <div className="flex flex-col items-center">
          <div className="rounded-full bg-indigo-100 dark:bg-indigo-900/50 p-3">
            <Mail className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
            Controlla la tua email
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Ti abbiamo inviato un'email con un link di verifica. Clicca sul link per verificare il tuo account.
          </p>
        </div>

        <div className="mt-8 space-y-4">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Non hai ricevuto l'email? Controlla la cartella spam o
          </p>
          <button
            onClick={handleResend}
            disabled={resending}
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {resending ? 'Invio in corso...' : 'Clicca qui per inviare nuovamente'}
          </button>
        </div>

        <div className="mt-8">
          <Link
            to="/login"
            className="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
          >
            Torna al login
          </Link>
        </div>
      </div>
    </div>
  );
};