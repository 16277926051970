import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MapPin, Phone, Globe, Mail, Clock, Calendar, Users, ChevronDown, ChevronUp } from 'lucide-react';
import { format, parse } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuctions } from '../hooks/useAuctions';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Countdown } from '../components/Countdown';
import { useLanguage } from '../context/LanguageContext';

interface Venue {
  name: string;
  description: string;
  address: string;
  phone: string;
  email: string;
  website: string;
  openingHours: {
    weekdays: string;
    weekends: string;
  };
  profileImage?: string;
}

export const SportsCenter: React.FC = () => {
  const { id } = useParams();
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const { auctions } = useAuctions();
  const venueAuctions = auctions?.filter(auction => auction.court?.venue?.id === id);
  const { t } = useLanguage();

  useEffect(() => {
    const fetchVenue = async () => {
      if (!id) return;
      
      try {
        const venueDoc = await getDoc(doc(db, 'venues', id));
        if (venueDoc.exists()) {
          setVenue(venueDoc.data() as Venue);
        }
      } catch (error) {
        console.error('Error fetching venue:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVenue();
  }, [id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!venue) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500 dark:text-gray-400">Venue not found</p>
      </div>
    );
  }

  // Function to preserve line breaks and bullet points in description
  const formatDescription = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.trim().startsWith('-') ? (
          <li className="ml-4">{line.substring(1).trim()}</li>
        ) : (
          <p className="mb-2">{line}</p>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-900">
      {/* Hero Section - only venue name overlaps */}
      <div className="relative h-[400px]">
        {venue?.profileImage ? (
          <img
            src={venue.profileImage}
            alt={venue.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-r from-indigo-500 to-purple-600 flex items-center justify-center">
            <span className="text-white text-2xl font-semibold">{venue.name}</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent" />
        
        {/* Only Venue Name in Overlay */}
        <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold mb-2">{venue.name}</h1>
          </div>
        </div>
      </div>

      {/* Description Section - Full Width */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white dark:bg-dark-800 rounded-lg p-6 shadow-sm mb-6">
          <div className={`prose dark:prose-invert max-w-none ${!isDescriptionExpanded ? 'line-clamp-2' : ''}`}>
            {formatDescription(venue.description)}
          </div>
          <button
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            className="mt-2 text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 flex items-center text-sm font-medium"
          >
            {isDescriptionExpanded ? (
              <>{t('venue.showLess')} <ChevronUp className="h-4 w-4 ml-1" /></>
            ) : (
              <>{t('venue.readMore')} <ChevronDown className="h-4 w-4 ml-1" /></>
            )}
          </button>
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Contact Info & Hours */}
          <div className="space-y-6">
            {/* Contact Info Card */}
            <div className="bg-white dark:bg-dark-800 rounded-lg p-6 shadow-sm space-y-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {t('venue.contactInfo')}
              </h3>
              <div className="space-y-3">
                <a 
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venue.address)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-600 dark:text-gray-300 hover:text-indigo-600 transition-colors"
                >
                  <MapPin className="h-5 w-5 mr-2 flex-shrink-0" />
                  <span>{venue.address}</span>
                </a>
                <a href={`tel:${venue.phone}`} className="flex items-center text-gray-600 dark:text-gray-300 hover:text-indigo-600">
                  <Phone className="h-5 w-5 mr-2 flex-shrink-0" />
                  {venue.phone}
                </a>
                <a href={`mailto:${venue.email}`} className="flex items-center text-gray-600 dark:text-gray-300 hover:text-indigo-600">
                  <Mail className="h-5 w-5 mr-2 flex-shrink-0" />
                  {venue.email}
                </a>
                <a href={venue.website} target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-600 dark:text-gray-300 hover:text-indigo-600">
                  <Globe className="h-5 w-5 mr-2 flex-shrink-0" />
                  {venue.website}
                </a>
              </div>
            </div>

            {/* Opening Hours Card */}
            <div className="bg-white dark:bg-dark-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                {t('venue.openingHours')}
              </h3>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{t('auction.weekdays')}</p>
                  <p className="text-gray-900 dark:text-white">{venue.openingHours.weekdays}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{t('auction.weekends')}</p>
                  <p className="text-gray-900 dark:text-white">{venue.openingHours.weekends}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Active Auctions */}
          <div className="lg:col-span-2">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              {t('venue.activeAuctions')}
            </h2>
            <div className="space-y-4">
              {venueAuctions?.map((auction) => {
                try {
                  const eventStartTime = parse(
                    `${auction.date} ${auction.startTime}`,
                    'yyyy-MM-dd HH:mm',
                    new Date()
                  );

                  return (
                    <div key={auction.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
                      <div className="p-6">
                        <div className="flex justify-between items-start">
                          <div className="flex items-center gap-2">
                            <Link 
                              to={`/auction/${auction.id}`}
                              className="text-base font-semibold text-gray-900 dark:text-white hover:text-indigo-600 dark:hover:text-indigo-400"
                            >
                              {auction.court?.name}
                            </Link>
                            <span className="inline-block px-2 py-0.5 text-xs font-semibold bg-indigo-100 dark:bg-indigo-900/50 text-indigo-800 dark:text-indigo-300 rounded">
                              {auction.court?.type}
                            </span>
                            <span className="inline-block px-2 py-0.5 text-xs font-semibold bg-white dark:bg-white text-gray-700 dark:text-gray-700 rounded">
                              {auction.court?.type2}
                            </span>
                          </div>
                          <div className="text-right">
                            <p className="text-xs text-gray-500 dark:text-gray-400">{t('bid.currentBid')}</p>
                            <p className="text-lg font-bold text-indigo-600 dark:text-indigo-400">
                              €{auction.currentBid}
                            </p>
                          </div>
                        </div>

                        <div className="mt-2 flex flex-col space-y-1 text-xs text-gray-500 dark:text-gray-400">
                          <div className="flex items-center">
                            <Calendar className="h-3.5 w-3.5 mr-1" />
                            {format(eventStartTime, 'MMM d, yyyy')}
                          </div>
                          <div className="flex items-center">
                            <Clock className="h-3.5 w-3.5 mr-1" />
                            {format(parse(auction.startTime, 'HH:mm', new Date()), 'HH:mm')} - {' '}
                            {format(parse(auction.endTime, 'HH:mm', new Date()), 'HH:mm')}
                          </div>
                        </div>

                        <div className="mt-2 flex justify-between items-center">
                          <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
                            <Users className="h-3.5 w-3.5 mr-1" />
                            {auction.participants || 0} {t('bid.participants')}
                          </div>
                          <div className="flex items-center text-xs text-orange-500 dark:text-orange-400">
                            <Clock className="h-3.5 w-3.5 mr-1" />
                            <Countdown endTime={new Date(auction.auctionEndTime)} />
                          </div>
                        </div>

                        <Link
                          to={`/auction/${auction.id}`}
                          className="mt-2 block w-full bg-indigo-600 text-white text-center py-1.5 px-4 rounded text-sm hover:bg-indigo-700 transition-colors"
                        >
                          {t('venue.viewAuction')}
                        </Link>
                      </div>
                    </div>
                  );
                } catch (error) {
                  console.error('Error rendering auction card:', error, auction);
                  return null;
                }
              })}

              {(!venueAuctions || venueAuctions.length === 0) && (
                <div className="text-center py-8 bg-white dark:bg-dark-800 rounded-lg shadow-md">
                  <p className="text-gray-500 dark:text-gray-400">{t('venue.noActiveAuctions')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};