import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  sendEmailVerification,
  applyActionCode,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../lib/firebase';
import { db } from '../lib/collections';
import type { User } from '../types';
import { emailService } from './email.service';

export const registerUser = async (
  email: string, 
  password: string, 
  firstName: string, 
  lastName: string
): Promise<{ user: User; needsEmailVerification: boolean }> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    await updateProfile(userCredential.user, {
      displayName: `${firstName} ${lastName}`
    });

    const userData: Omit<User, 'id'> = {
      email,
      firstName,
      lastName,
      role: 'user',
      createdAt: new Date(),
      lastActive: new Date(),
      emailVerified: false,
      status: 'active',
      hasLoggedInBefore: false
    };

    await setDoc(doc(db, 'users', userCredential.user.uid), {
      ...userData,
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp()
    });

    // Send both Firebase verification email and welcome email
    const actionCodeSettings = {
      url: `${window.location.origin}/login`,
      handleCodeInApp: true
    };
    await sendEmailVerification(userCredential.user, actionCodeSettings);
    
    // Add this: Send welcome email via Postmark
    await emailService.sendUserWelcome(email, {
      firstName,
      email
    });

    return {
      user: {
        id: userCredential.user.uid,
        ...userData
      },
      needsEmailVerification: true
    };
  } catch (error) {
    console.error('Detailed registration error:', error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Get user data from Firestore
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const userData = userDoc.data();

    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    // Return the user role for routing
    return {
      uid: user.uid,
      email: user.email,
      role: userData.role
    };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  await signOut(auth);
};

export const getCurrentUser = async (userId: string): Promise<User | null> => {
  const userDoc = await getDoc(doc(db, 'users', userId));
  if (!userDoc.exists()) return null;
  return {
    id: userDoc.id,
    ...userDoc.data()
  } as User;
};

export const resendVerificationEmail = async (): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user is currently signed in');
  }

  await sendEmailVerification(user, {
    url: `${window.location.origin}/verify-email`,
    handleCodeInApp: true
  });
};

export const verifyEmail = async (actionCode: string): Promise<void> => {
  try {
    // Apply the verification code
    await applyActionCode(auth, actionCode);
    
    // Force refresh the current user to get updated emailVerified status
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user found after verification');
    }
    
    await user.reload();

    // Double check that email is actually verified
    if (!user.emailVerified) {
      throw new Error('Email verification failed');
    }

    // Get user data from Firestore
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const userData = userDoc.data();
    
    if (!userData) {
      throw new Error('User data not found');
    }

    // Update Firestore
    await setDoc(doc(db, 'users', user.uid), {
      emailVerified: true,
      lastActive: serverTimestamp()
    }, { merge: true });

    // Send welcome email via Postmark after successful verification
    await emailService.sendUserWelcome(userData.email, {
      firstName: userData.firstName,
      email: userData.email
    });

    console.log('Email verification and welcome email sent successfully');
  } catch (error) {
    console.error('Detailed error in verifyEmail:', error);
    throw error;
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    // Only send Firebase's password reset email
    await firebaseSendPasswordResetEmail(auth, email, {
      url: `${window.location.origin}/login`, // Redirect to login page after reset
      handleCodeInApp: true
    });
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};