import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../lib/firebase";
import { stripeService } from "../services/stripe.service";

export const useStripeConnect = () => {
	const { user } = useAuth();
	const [isConnected, setIsConnected] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [stripeAccountId, setStripeAccountId] = useState<string | null>(null);

	useEffect(() => {
		const checkStripeStatus = async () => {
			if (!user?.id) {
				console.log("useStripeConnect - No user ID");
				setIsLoading(false);
				return;
			}

			try {
				const stripeDoc = await getDoc(doc(db, "stripeConnect", user.id));
				const stripeAccountId = stripeDoc.data()?.stripeAccountId;

				const stripeAccount = await stripeService.retriveAccount();
				const isConnected = stripeDoc.exists() && stripeDoc.data()?.status === "active";

				// recheck if the account is still on onboarding phase
				// https://stackoverflow.com/questions/65590218/how-to-know-if-stripe-connect-connected-user-onboarding-processes-has-completed
				const isStillRequireOnboarding = stripeAccount?.requirements?.currently_due.length > 0;
				if (isStillRequireOnboarding && isConnected) {
					// update stripeConnect collection of status to onboarding
					await updateDoc(doc(db, "stripeConnect", user.id), {
						status: "onboarding",
					});
				} 
				if (!isStillRequireOnboarding && !isConnected) { 
					await updateDoc(doc(db, "stripeConnect", user.id), {
						status: "active",
					});
				}

				setStripeAccountId(stripeAccountId);
				setIsConnected(!isStillRequireOnboarding);
			} catch (error) {
				console.error("useStripeConnect - Error:", error);
			}
			finally {
				setIsLoading(false);
			}
		};

		checkStripeStatus();
	}, [user]);

	return { isConnected, isLoading, stripeAccountId };
};
