import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { CheckCircle, XCircle, Mail } from 'lucide-react';
import { verifyEmail, resendVerificationEmail } from '../services/auth.service';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { useNotification } from '../context/NotificationContext';
import { useLanguage } from '../context/LanguageContext';

export const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [resending, setResending] = useState(false);
  const { showNotification } = useNotification();
  const { t } = useLanguage();

  const handleResend = async () => {
    try {
      setResending(true);
      await resendVerificationEmail();
      showNotification('Email di verifica reinviata con successo', 'success');
    } catch (error) {
      console.error('Error resending verification email:', error);
      showNotification(
        error instanceof Error ? error.message : 'Errore durante il reinvio dell\'email',
        'error'
      );
    } finally {
      setResending(false);
    }
  };

  useEffect(() => {
    const verify = async () => {
      try {
        const oobCode = searchParams.get('oobCode');
        if (!oobCode) {
          setVerifying(false); // Stop loading if no code
          return; // Don't throw error, just show the resend interface
        }

        await verifyEmail(oobCode);
        setVerifying(false);
        showNotification('Email verificata con successo!', 'success');
        
        // Redirect to dashboard after 3 seconds
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);
      } catch (err) {
        console.error('Verification error:', err);
        setError(err instanceof Error ? err.message : 'Verification failed');
        setVerifying(false);
        showNotification('Verifica email fallita', 'error');
      }
    };

    verify();
  }, [searchParams, navigate, showNotification]);

  if (verifying) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-blue-50 dark:from-dark-900 dark:to-dark-800">
        <LoadingSpinner />
      </div>
    );
  }

  // If no oobCode, show the email check interface
  if (!searchParams.get('oobCode')) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-blue-50 dark:from-dark-900 dark:to-dark-800 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white dark:bg-dark-800 p-8 rounded-xl shadow-lg text-center">
          <div className="flex flex-col items-center">
            <div className="rounded-full bg-indigo-100 dark:bg-indigo-900/50 p-3">
              <Mail className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
            </div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              {t('emailVerification.title')}
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {t('emailVerification.subtitle')}
            </p>
          </div>

          <div className="mt-8 space-y-4">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {t('emailVerification.checkSpam')}
            </p>
            <button
              onClick={handleResend}
              disabled={resending}
              className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {resending ? 'Invio in corso...' : t('emailVerification.resend')}
            </button>
          </div>

          <div className="mt-8">
            <Link
              to="/login"
              className="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
            >
              {t('emailVerification.returnToLogin')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // Show verification result
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-blue-50 dark:from-dark-900 dark:to-dark-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white dark:bg-dark-800 p-8 rounded-xl shadow-lg text-center">
        {error ? (
          <>
            <XCircle className="mx-auto h-12 w-12 text-red-500" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              {t('emailVerification.verificationFailed')}
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{error}</p>
          </>
        ) : (
          <>
            <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              {t('emailVerification.emailVerified')}
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {t('emailVerification.redirecting')}
            </p>
          </>
        )}
      </div>
    </div>
  );
};