import React from 'react';
import { useLanguage } from '../context/LanguageContext';

interface Subsection {
  title: string;
  content: string;
}

interface Section {
  title: string;
  subsections: Subsection[];
}

interface TermsContent {
  title: string;
  sections: Section[];
  lastUpdated: string;
}

interface TermsData {
  en: TermsContent;
  it: TermsContent;
}

const termsContent: TermsData = {
  en: {
    title: "Bidcourt Terms of Service",
    sections: [
      {
        title: "1. Introduction and Acceptance",
        subsections: [
          {
            title: "1.1 Platform Overview",
            content: 'Bidcourt is an online auction platform dedicated to facilitating the rental of sports facilities and courts. By accessing or using the Bidcourt platform, you agree to be bound by these Terms of Service ("Terms"), which constitute a legally binding agreement between you and Bidcourt.'
          },
          {
            title: "1.2 Acceptance of Terms",
            content: "By creating an account, placing a bid, or using any service on Bidcourt, you acknowledge that you have read, understood, and agree to these Terms in their entirety."
          }
        ]
      },
      {
        title: "2. Definitions",
        subsections: [
          {
            title: "",
            content: `"Platform" refers to the Bidcourt online auction service
"Vendor" means a court or venue owner who lists sports facilities for auction
"Bidder" means a user who participates in court time slot auctions
"Auction" means the time-limited bidding process for court rentals`
          }
        ]
      },
      {
        title: "3. User Accounts",
        subsections: [
          {
            title: "3.1 Account Registration",
            content: `Users must provide accurate, current, and complete information during registration
Users must be at least 16 years of age to create an account
Each user may maintain only one active account`
          },
          {
            title: "3.2 Account Responsibilities",
            content: `Users are responsible for maintaining the confidentiality of their account credentials
Users are liable for all activities conducted under their account
Bidcourt reserves the right to suspend or terminate accounts for violation of these Terms`
          }
        ]
      },
      {
        title: "4. Auction Process and Rules",
        subsections: [
          {
            title: "4.1 Bidding Mechanism",
            content: `Minimum bid increments are €5
Bids are binding and considered a legal commitment to rent the facility
Real-time bid tracking is provided, but Bidcourt does not guarantee uninterrupted service`
          },
          {
            title: "4.2 Auction Participation",
            content: `Vendors set base prices and auction parameters
Bidders can place bids on available court time slots
Successful bids result in a binding rental agreement`
          },
          {
            title: "4.3 Payment and Fees",
            content: `All transactions are processed in Euros (EUR)
Payment is processed through Stripe
Bidcourt charges:
• Base price goes entirely to the Vendor
• 40% of the margin above the base price is retained by Bidcourt as a service fee

Pre-authorization of payment is required for bid placement`
          }
        ]
      },
      {
        title: "5. User Conduct and Restrictions",
        subsections: [
          {
            title: "5.1 Prohibited Activities",
            content: `Users agree NOT to:
• Use the platform for illegal or unauthorized purposes
• Misrepresent identity or affiliation
• Interfere with platform operations
• Engage in fraudulent bidding
• Harass or discriminate against other users`
          },
          {
            title: "5.2 Content Guidelines",
            content: `Users retain ownership of their content
Users grant Bidcourt a non-exclusive, worldwide license to use uploaded content
Bidcourt reserves the right to remove inappropriate content`
          }
        ]
      },
      {
        title: "6. Data Privacy and Security",
        subsections: [
          {
            title: "6.1 Data Collection",
            content: `Personal data is collected and stored using Firebase Firestore
Data collection complies with GDPR and European data protection regulations
Collected information includes: name, email, user role`
          },
          {
            title: "6.2 User Data Rights",
            content: `Users have the right to:
• Access their personal data
• Request data correction
• Request data deletion
• Withdraw consent for data processing`
          },
          {
            title: "6.3 Data Protection",
            content: `Implemented security measures include:
• Firebase Authentication
• Firestore Security Rules
• Secure Stripe payment processing`
          }
        ]
      },
      {
        title: "7. Intellectual Property",
        subsections: [
          {
            title: "7.1 Platform Ownership",
            content: `Bidcourt's design, software, graphical interfaces, and underlying technology are the exclusive property of Bidcourt
Users may not reproduce, distribute, or create derivative works without explicit permission`
          }
        ]
      },
      {
        title: "8. Liability and Disclaimers",
        subsections: [
          {
            title: "8.1 Platform Limitations",
            content: `Bidcourt acts solely as an intermediary for court rentals
We do not guarantee the availability, quality, or suitability of listed facilities
Users interact with Vendors at their own risk`
          },
          {
            title: "8.2 Limitation of Liability",
            content: `Bidcourt is not liable for:
• Direct or indirect damages from platform use
• Vendor actions or facility conditions
• Auction disputes
• Technical interruptions`
          }
        ]
      },
      {
        title: "9. Dispute Resolution",
        subsections: [
          {
            title: "9.1 Dispute Handling",
            content: `Users must attempt to resolve disputes directly with the counterparty
Bidcourt may assist in mediation but is not obligated to resolve conflicts`
          },
          {
            title: "9.2 Jurisdiction",
            content: `These Terms are governed by the laws of the European Union
Any legal disputes will be resolved in the appropriate jurisdiction within the EU`
          }
        ]
      },
      {
        title: "10. Modifications to Terms",
        subsections: [
          {
            title: "10.1 Terms Updates",
            content: `Bidcourt reserves the right to modify these Terms
Significant changes will be communicated to users via email
Continued platform use after modifications constitutes acceptance of new Terms`
          }
        ]
      },
      {
        title: "11. Termination",
        subsections: [
          {
            title: "11.1 Account Termination",
            content: `Bidcourt may terminate or suspend accounts for:
• Violation of Terms
• Fraudulent activity
• Repeated non-compliance
• Suspicious or illegal behavior`
          }
        ]
      },
      {
        title: "12. Contact Information",
        subsections: [
          {
            title: "",
            content: `For questions, concerns, or legal inquiries, please contact:

Email: legal@bidcourt.com
Address: [Your Company's Registered Address]`
          }
        ]
      },
      {
        title: "13. Acknowledgment",
        subsections: [
          {
            title: "",
            content: "By using Bidcourt, you acknowledge that you have read, understood, and agree to these Terms of Service."
          }
        ]
      }
    ],
    lastUpdated: "Last Updated: 26/11/2024"
  },
  it: {
    title: "Bidcourt Termini di Servizio",
    sections: [
      {
        title: "1. Introduzione e Accettazione",
        subsections: [
          {
            title: "1.1 Panoramica della Piattaforma",
            content: 'Bidcourt è una piattaforma di aste online dedicata alla facilitazione dell\'affitto di impianti sportivi e campi. Accedendo o utilizzando la piattaforma Bidcourt, si accettano i presenti Termini di Servizio ("Termini"), che costituiscono un accordo giuridicamente vincolante tra l\'utente e Bidcourt.'
          },
          {
            title: "1.2 Accettazione dei Termini",
            content: "Creando un account, effettuando un'offerta o utilizzando qualsiasi servizio su Bidcourt, si riconosce di aver letto, compreso e accettato integralmente questi Termini."
          }
        ]
      },
      {
        title: "2. Definizioni",
        subsections: [
          {
            title: "",
            content: `"Piattaforma" si riferisce al servizio di aste online Bidcourt
"Venditore" indica il proprietario di un campo o di un impianto che mette all'asta gli spazi sportivi
"Offerente" indica un utente che partecipa alle aste per gli slot temporali dei campi
"Asta" indica il processo di offerta a tempo limitato per gli affitti dei campi`
          }
        ]
      },
      {
        title: "3. Account Utente",
        subsections: [
          {
            title: "3.1 Registrazione Account",
            content: `Gli utenti devono fornire informazioni accurate, attuali e complete durante la registrazione
Gli utenti devono avere almeno 16 anni per creare un account
Ogni utente può mantenere un solo account attivo`
          },
          {
            title: "3.2 Responsabilità dell'Account",
            content: `Gli utenti sono responsabili della riservatezza delle proprie credenziali di accesso
Gli utenti sono responsabili per tutte le attività svolte sul proprio account
Bidcourt si riserva il diritto di sospendere o terminare account in caso di violazione dei presenti Termini`
          }
        ]
      },
      {
        title: "4. Processo e Regole dell'Asta",
        subsections: [
          {
            title: "4.1 Meccanismo di Offerta",
            content: `Gli incrementi minimi delle offerte sono di 5 €
Le offerte sono vincolanti e considerate un impegno legale per l'affitto dell'impianto
Il monitoraggio delle offerte in tempo reale è fornito, ma Bidcourt non garantisce un servizio ininterrotto`
          },
          {
            title: "4.2 Partecipazione all'Asta",
            content: `I Venditori impostano i prezzi base e i parametri dell'asta
Gli Offerenti possono fare offerte sugli slot temporali dei campi disponibili
Le offerte di successo determinano un contratto di affitto vincolante`
          },
          {
            title: "4.3 Pagamento e Commissioni",
            content: `Tutte le transazioni sono elaborate in Euro (EUR)
Il pagamento è elaborato tramite Stripe
Commissioni di Bidcourt:
• Il prezzo base va interamente al Venditore
• Il 40% del margine sopra il prezzo base è trattenuto da Bidcourt come commissione di servizio

È richiesta la pre-autorizzazione del pagamento per effettuare un'offerta`
          }
        ]
      },
      {
        title: "5. Condotta dell'Utente e Restrizioni",
        subsections: [
          {
            title: "5.1 Attività Vietate",
            content: `Gli utenti si impegnano a NON:
• Utilizzare la piattaforma per scopi illegali o non autorizzati
• Rappresentare falsamente la propria identità o affiliazione
• Interferire con il funzionamento della piattaforma
• Impegnarsi in offerte fraudolente
• Molestare o discriminare altri utenti`
          },
          {
            title: "5.2 Linee Guida sui Contenuti",
            content: `Gli utenti mantengono la proprietà dei propri contenuti
Gli utenti concedono a Bidcourt una licenza non esclusiva mondiale per utilizzare i contenuti caricati
Bidcourt si riserva il diritto di rimuovere contenuti inappropriati`
          }
        ]
      },
      {
        title: "6. Privacy e Sicurezza dei Dati",
        subsections: [
          {
            title: "6.1 Raccolta Dati",
            content: `I dati personali sono raccolti e archiviati utilizzando Firebase Firestore
La raccolta dei dati è conforme al GDPR e alle normative europee di protezione dei dati
Le informazioni raccolte includono: nome, email, ruolo utente`
          },
          {
            title: "6.2 Diritti dei Dati Utente",
            content: `Gli utenti hanno il diritto di:
• Accedere ai propri dati personali
• Richiedere la correzione dei dati
• Richiedere la cancellazione dei dati
• Revocare il consenso al trattamento dei dati`
          },
          {
            title: "6.3 Protezione dei Dati",
            content: `Le misure di sicurezza implementate includono:
• Autenticazione Firebase
• Regole di Sicurezza Firestore
• Elaborazione di pagamenti sicura tramite Stripe`
          }
        ]
      },
      {
        title: "7. Proprietà Intellettuale",
        subsections: [
          {
            title: "7.1 Proprietà della Piattaforma",
            content: `Design, software, interfacce grafiche e tecnologia sottostante di Bidcourt sono di esclusiva proprietà di Bidcourt
Gli utenti non possono riprodurre, distribuire o creare opere derivate senza esplicita autorizzazione`
          }
        ]
      },
      {
        title: "8. Limitazione di Responsabilità",
        subsections: [
          {
            title: "8.1 Limiti della Piattaforma",
            content: `Bidcourt agisce esclusivamente come intermediario per gli affitti dei campi
Non garantiamo la disponibilità, qualità o idoneità degli impianti elencati
Gli utenti interagiscono con i Venditori a proprio rischio`
          },
          {
            title: "8.2 Limitazione di Responsabilità",
            content: `Bidcourt non è responsabile per:
• Danni diretti o indiretti derivanti dall'uso della piattaforma
• Azioni dei Venditori o condizioni degli impianti
• Controversie sulle aste
• Interruzioni tecniche`
          }
        ]
      },
      {
        title: "9. Risoluzione delle Controversie",
        subsections: [
          {
            title: "9.1 Gestione delle Controversie",
            content: `Gli utenti devono tentare di risolvere le controversie direttamente con la controparte
Bidcourt può assistere nella mediazione ma non è obbligata a risolvere conflitti`
          },
          {
            title: "9.2 Giurisdizione",
            content: `Questi Termini sono regolati dalle leggi dell'Unione Europea
Eventuali controversie legali saranno risolte nella giurisdizione appropriata all'interno dell'UE`
          }
        ]
      },
      {
        title: "10. Modifiche ai Termini",
        subsections: [
          {
            title: "10.1 Aggiornamenti dei Termini",
            content: `Bidcourt si riserva il diritto di modificare questi Termini
Modifiche significative saranno comunicate agli utenti via email
L'utilizzo continuato della piattaforma dopo le modifiche costituisce accettazione dei nuovi Termini`
          }
        ]
      },
      {
        title: "11. Risoluzione",
        subsections: [
          {
            title: "11.1 Chiusura dell'Account",
            content: `Bidcourt può terminare o sospendere account per:
• Violazione dei Termini
• Attività fraudolenta
• Ripetuta non conformità
• Comportamento sospetto o illegale`
          }
        ]
      },
      {
        title: "12. Informazioni di Contatto",
        subsections: [
          {
            title: "",
            content: `Per domande, preoccupazioni o richieste legali, contattare:

Email: legal@bidcourt.com
Indirizzo: [Indirizzo Legale Registrato dell'Azienda]`
          }
        ]
      },
      {
        title: "13. Riconoscimento",
        subsections: [
          {
            title: "",
            content: "Utilizzando Bidcourt, si riconosce di aver letto, compreso e accettato questi Termini di Servizio."
          }
        ]
      }
    ],
    lastUpdated: "Ultimo Aggiornamento: 26/11/2024"
  }
};

const TermsOfService: React.FC = () => {
  const { language } = useLanguage();
  const content = termsContent[language as keyof typeof termsContent];

  return (
    <div className="bg-gray-50 dark:bg-dark-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          {content.title}
        </h1>

        <div className="space-y-8 text-gray-700 dark:text-gray-300">
          {content.sections.map((section, index) => (
            <section key={index} className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className="mb-4">
                    {subsection.title && (
                      <h3 className="text-lg font-medium mb-2">
                        {subsection.title}
                      </h3>
                    )}
                    <div className="whitespace-pre-line text-base">
                      {subsection.content}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ))}

          <p className="text-sm text-gray-500 dark:text-gray-400 mt-8">
            {content.lastUpdated}
          </p>
        </div>
      </div>
    </div>
  );
};

export { TermsOfService }; 