import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, DocumentData, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Auction } from '../types';

interface UseAuctionsOptions {
  status?: 'active' | 'closed';
  venueId?: string;
}

export const useAuctions = (options: UseAuctionsOptions = {}) => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auctionsRef = collection(db, 'auctions');
    
    // Build query conditions
    const queryConditions = [
      where('status', '==', options.status || 'active'),
      orderBy('date', 'desc'),
      orderBy('startTime', 'desc'),
      orderBy('auctionEndTime', 'asc')
    ];

    // Add venueId filter if provided
    if (options.venueId) {
      queryConditions.unshift(where('venueId', '==', options.venueId));
    }

    const auctionsQuery = query(auctionsRef, ...queryConditions);

    const unsubscribe = onSnapshot(
      auctionsQuery,
      async (snapshot) => {
        try {
          const auctionsData = await Promise.all(
            snapshot.docs.map(async (auctionDoc) => {
              const data = auctionDoc.data() as DocumentData;
              
              // Fetch court data
              const courtRef = doc(db, 'courts', data.courtId);
              const courtSnap = await getDoc(courtRef);
              const courtData = courtSnap.data() as DocumentData;
              
              // Fetch venue data
              const venueRef = doc(db, 'venues', data.venueId);
              const venueSnap = await getDoc(venueRef);
              const venueData = venueSnap.data() as DocumentData;

              // Log the data we're working with
              console.log('Auction data:', data);
              console.log('Court data:', courtData);
              console.log('Venue data:', venueData);

              return {
                id: auctionDoc.id,
                courtId: data.courtId,
                date: data.date,
                startTime: data.startTime,
                endTime: data.endTime,
                basePrice: data.basePrice,
                currentBid: data.currentBid,
                participants: data.participants || 0,
                status: data.status,
                createdAt: data.createdAt,
                court: {
                  id: data.courtId,
                  name: courtData?.name || 'Unnamed Court',
                  type: courtData?.type || 'Unknown Type',
                  type2: courtData?.type2 || 'Outdoor',
                  image: courtData?.image,
                  venue: venueData ? {
                    id: data.venueId,
                    name: venueData.name || 'Unknown Venue',
                    profileImage: venueData.profileImage || null,
                  } : undefined,
                },
                auctionEndTime: data.auctionEndTime,
              } as Auction;
            })
          );

          console.log('Processed auctions:', auctionsData);
          // Sort auctions by countdown time (closest to ending first)
          const sortedAuctions = auctionsData.sort((a, b) => {
            const aEndTime = new Date(a.auctionEndTime).getTime();
            const bEndTime = new Date(b.auctionEndTime).getTime();
            return aEndTime - bEndTime;
          });
          setAuctions(sortedAuctions);
          setLoading(false);
        } catch (error) {
          console.error('Error processing auctions:', error);
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [options.status, options.venueId]);

  return { auctions, loading };
};