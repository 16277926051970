import React from 'react';
import { useLanguage } from '../context/LanguageContext';

interface Subsection {
  title: string;
  content: string;
}

interface Section {
  title: string;
  subsections: Subsection[];
}

interface PrivacyContent {
  title: string;
  sections: Section[];
  lastUpdated: string;
}

interface PrivacyData {
  en: PrivacyContent;
  it: PrivacyContent;
}

const privacyContent: PrivacyData = {
  en: {
    title: "Bidcourt Privacy Policy",
    sections: [
      {
        title: "1. Introduction",
        subsections: [
          {
            title: "1.1 Purpose of this Policy",
            content: 'This Privacy Policy describes how Bidcourt ("we", "our", "us") collects, uses, processes, and protects your personal data when you use our online sports facility auction platform.'
          },
          {
            title: "1.2 Legal Compliance",
            content: `We are committed to protecting your privacy in compliance with:

• General Data Protection Regulation (GDPR)
• European Union Data Protection Laws
• Italian and European Privacy Regulations`
          }
        ]
      },
      {
        title: "2. Information We Collect",
        subsections: [
          {
            title: "2.1 Personal Information",
            content: `We collect the following personal information during registration and platform use:

• First Name
• Last Name
• Email Address
• User Role (Vendor or Bidder)
• Account Authentication Details`
          },
          {
            title: "2.2 Technical Information",
            content: `We automatically collect:

• IP Address
• Device Information
• Browser Type
• Access Times
• Platform Usage Data`
          },
          {
            title: "2.3 Payment-Related Information",
            content: `• Stripe processes and stores payment information
• We do not directly store full payment details
• Transaction records are maintained for financial and legal compliance`
          }
        ]
      },
      {
        title: "3. How We Collect Information",
        subsections: [
          {
            title: "3.1 Collection Methods",
            content: `• Direct user registration
• Firebase Authentication
• Automated collection during platform interaction
• Stripe payment integration`
          },
          {
            title: "3.2 Cookies and Tracking",
            content: `• We use minimal tracking technologies
• Users can manage cookie preferences
• Analytics are used for platform improvement`
          }
        ]
      },
      {
        title: "4. Purposes of Data Processing",
        subsections: [
          {
            title: "4.1 Primary Purposes",
            content: `We process your data to:

• Manage user accounts
• Enable auction and bidding processes
• Process payments
• Provide customer support
• Ensure platform security`
          },
          {
            title: "4.2 Legal Basis for Processing",
            content: `Our data processing is based on:

• User consent
• Contractual necessity
• Legal obligations
• Legitimate business interests`
          }
        ]
      },
      {
        title: "5. Data Sharing and Disclosure",
        subsections: [
          {
            title: "5.1 Third-Party Services",
            content: `We share data with:

• Firebase (Authentication and Cloud Storage)
• Stripe (Payment Processing)
• Legal authorities if required by law`
          },
          {
            title: "5.2 Data Transfer",
            content: `• Data may be processed within the European Union
• We ensure adequate data protection standards`
          }
        ]
      },
      {
        title: "6. User Data Rights",
        subsections: [
          {
            title: "6.1 Your Rights",
            content: `Users have the right to:

• Access their personal data
• Request data correction
• Request data deletion
• Withdraw consent
• Object to data processing
• Request data portability`
          },
          {
            title: "6.2 Exercising Rights",
            content: `To exercise these rights, contact:

Email: info@bidcourt.it
We will respond within 30 days`
          }
        ]
      },
      {
        title: "7. Data Security",
        subsections: [
          {
            title: "7.1 Security Measures",
            content: `• Firebase Authentication
• Firestore Security Rules
• Secure Stripe payment processing
• Regular security audits
• Encryption of sensitive data
• Access controls and authentication`
          },
          {
            title: "7.2 Data Breach Notification",
            content: "We will notify users and relevant authorities within 72 hours of discovering a significant data breach."
          }
        ]
      },
      {
        title: "8. Data Retention",
        subsections: [
          {
            title: "8.1 Retention Periods",
            content: `• Active User Accounts: Data maintained while account is active
• Transaction Records: Retained for 7 years for financial and legal compliance
• Inactive Accounts:
  - User data anonymized after 2 years of inactivity
  - Complete account deletion after 3 years of inactivity
• Auction and Bid History: Retained for 5 years for historical and dispute resolution purposes`
          },
          {
            title: "8.2 Data Deletion Requests",
            content: `• Users can request complete account deletion at any time
• Deletion requests processed within 30 days
• Some transaction records may be retained for legal compliance`
          }
        ]
      },
      {
        title: "9. Privacy Coordination",
        subsections: [
          {
            title: "9.1 Privacy Coordinator",
            content: `While we are not legally required to appoint a Data Protection Officer, we provide a dedicated privacy contact:

Privacy Coordinator: Company Contact Point
Contact Email: info@bidcourt.it
Responsible for:
• Handling data protection inquiries
• Managing user data rights
• Ensuring ongoing privacy compliance`
          }
        ]
      },
      {
        title: "10. Children's Privacy",
        subsections: [
          {
            title: "10.1 Age Restrictions",
            content: `• Bidcourt is not intended for users under 18
• We do not knowingly collect data from minors`
          }
        ]
      },
      {
        title: "11. International Users",
        subsections: [
          {
            title: "11.1 Geographic Scope",
            content: `• Primary focus on European market
• GDPR and EU data protection laws apply
• Users outside the EU are subject to these terms`
          }
        ]
      },
      {
        title: "12. Changes to Privacy Policy",
        subsections: [
          {
            title: "12.1 Policy Updates",
            content: `• We may update this policy periodically
• Users will be notified of significant changes
• Continued platform use implies acceptance of updates`
          }
        ]
      },
      {
        title: "13. Contact Information",
        subsections: [
          {
            title: "",
            content: `For privacy-related inquiries:

Email: info@bidcourt.it`
          }
        ]
      },
      {
        title: "14. Acknowledgment",
        subsections: [
          {
            title: "",
            content: "By using Bidcourt, you acknowledge that you have read and understood this Privacy Policy."
          }
        ]
      }
    ],
    lastUpdated: "Last Updated: 26/11/2024"
  },
  it: {
    title: "Informativa sulla Privacy di Bidcourt",
    sections: [
      {
        title: "1. Introduzione",
        subsections: [
          {
            title: "1.1 Scopo di questa Informativa",
            content: 'Questa Informativa sulla Privacy descrive come Bidcourt ("noi", "nostro", "nostra") raccoglie, utilizza, elabora e protegge i tuoi dati personali quando utilizzi la nostra piattaforma di aste per impianti sportivi.'
          },
          {
            title: "1.2 Conformità Legale",
            content: `Siamo impegnati a proteggere la tua privacy in conformità con:

• Regolamento Generale sulla Protezione dei Dati (GDPR)
• Leggi sulla Protezione dei Dati dell'Unione Europea
• Normative italiane ed europee sulla privacy`
          }
        ]
      },
      {
        title: "2. Informazioni Raccolte",
        subsections: [
          {
            title: "2.1 Informazioni Personali",
            content: `Raccogliamo le seguenti informazioni personali durante la registrazione e l'utilizzo della piattaforma:

• Nome
• Cognome
• Indirizzo Email
• Ruolo Utente (Venditore o Offerente)
• Dettagli di Autenticazione dell'Account`
          },
          {
            title: "2.2 Informazioni Tecniche",
            content: `Raccogliamo automaticamente:

• Indirizzo IP
• Informazioni sul Dispositivo
• Tipo di Browser
• Orari di Accesso
• Dati di Utilizzo della Piattaforma`
          },
          {
            title: "2.3 Informazioni Relative ai Pagamenti",
            content: `• Stripe elabora e conserva le informazioni di pagamento
• Non conserviamo direttamente i dettagli completi di pagamento
• Manteniamo registri delle transazioni per conformità finanziaria e legale`
          }
        ]
      },
      {
        title: "3. Modalità di Raccolta delle Informazioni",
        subsections: [
          {
            title: "3.1 Metodi di Raccolta",
            content: `• Registrazione diretta dell'utente
• Autenticazione Firebase
• Raccolta automatizzata durante l'interazione con la piattaforma
• Integrazione di pagamento Stripe`
          },
          {
            title: "3.2 Cookie e Tracciamento",
            content: `• Utilizziamo tecnologie di tracciamento minime
• Gli utenti possono gestire le preferenze dei cookie
• Le analisi sono utilizzate per il miglioramento della piattaforma`
          }
        ]
      },
      {
        title: "4. Finalità del Trattamento dei Dati",
        subsections: [
          {
            title: "4.1 Finalità Primarie",
            content: `Trattiamo i tuoi dati per:

• Gestire l'account utente
• Abilitare processi di aste e offerte
• Elaborare pagamenti
• Fornire supporto clienti
• Garantire la sicurezza della piattaforma`
          },
          {
            title: "4.2 Base Giuridica del Trattamento",
            content: `Il nostro trattamento dei dati si basa su:

• Consenso dell'utente
• Necessità contrattuale
• Obblighi legali
• Interessi commerciali legittimi`
          }
        ]
      },
      {
        title: "5. Condivisione e Divulgazione dei Dati",
        subsections: [
          {
            title: "5.1 Servizi Terzi",
            content: `Condividiamo dati con:

• Firebase (Autenticazione e Archiviazione Cloud)
• Stripe (Elaborazione Pagamenti)
• Autorità legali se richiesto dalla legge`
          },
          {
            title: "5.2 Trasferimento Dati",
            content: `• I dati possono essere elaborati all'interno dell'Unione Europea
• Garantiamo standard di protezione dei dati adeguati`
          }
        ]
      },
      {
        title: "6. Diritti dell'Utente sui Dati",
        subsections: [
          {
            title: "6.1 I Tuoi Diritti",
            content: `Gli utenti hanno il diritto di:

• Accedere ai propri dati personali
• Richiedere la correzione dei dati
• Richiedere la cancellazione dei dati
• Revocare il consenso
• Opporsi al trattamento dei dati
• Richiedere la portabilità dei dati`
          },
          {
            title: "6.2 Esercizio dei Diritti",
            content: `Per esercitare questi diritti, contattare:

Email: info@bidcourt.it
Risponderemo entro 30 giorni`
          }
        ]
      },
      {
        title: "7. Sicurezza dei Dati",
        subsections: [
          {
            title: "7.1 Misure di Sicurezza",
            content: `• Autenticazione Firebase
• Regole di Sicurezza Firestore
• Elaborazione di pagamenti sicura tramite Stripe
• Audit di sicurezza regolari
• Crittografia dei dati sensibili
• Controlli di accesso e autenticazione`
          },
          {
            title: "7.2 Notifica di Violazione dei Dati",
            content: "Informeremo gli utenti e le autorità competenti entro 72 ore dalla scoperta di una significativa violazione dei dati."
          }
        ]
      },
      {
        title: "8. Conservazione dei Dati",
        subsections: [
          {
            title: "8.1 Periodi di Conservazione",
            content: `• Account Utente Attivi: Dati mantenuti durante l'attività dell'account
• Registri delle Transazioni: Conservati per 7 anni per conformità finanziaria e legale
• Account Inattivi:
  - Dati utente resi anonimi dopo 2 anni di inattività
  - Cancellazione completa dell'account dopo 3 anni di inattività
• Cronologia Aste e Offerte: Conservata per 5 anni per ragioni storiche e di risoluzione controversie`
          },
          {
            title: "8.2 Richieste di Cancellazione dei Dati",
            content: `• Gli utenti possono richiedere la cancellazione completa dell'account in qualsiasi momento
• Richieste di cancellazione elaborate entro 30 giorni
• Alcuni registri delle transazioni possono essere conservati per conformità legale`
          }
        ]
      },
      {
        title: "9. Coordinamento Privacy",
        subsections: [
          {
            title: "9.1 Coordinatore Privacy",
            content: `Pur non essendo legalmente obbligati a nominare un Responsabile della Protezione dei Dati, forniamo un contatto dedicato per la privacy:

Coordinatore Privacy: Punto di Contatto Aziendale
Email di Contatto: info@bidcourt.it
Responsabile di:
• Gestione delle richieste di protezione dei dati
• Gestione dei diritti sui dati degli utenti
• Garanzia della conformità alla privacy`
          }
        ]
      },
      {
        title: "10. Privacy per Utenti Minorenni",
        subsections: [
          {
            title: "10.1 Restrizioni di Età",
            content: `• Bidcourt non è destinato a utenti di età inferiore a 18 anni
• Non raccogliamo consapevolmente dati di minori`
          }
        ]
      },
      {
        title: "11. Utenti Internazionali",
        subsections: [
          {
            title: "11.1 Ambito Geografico",
            content: `• Focus principale sul mercato europeo
• Si applicano il GDPR e le leggi di protezione dei dati dell'UE
• Gli utenti al di fuori dell'UE sono soggetti a questi termini`
          }
        ]
      },
      {
        title: "12. Modifiche all'Informativa sulla Privacy",
        subsections: [
          {
            title: "12.1 Aggiornamenti dell'Informativa",
            content: `• Potremmo aggiornare periodicamente questa informativa
• Gli utenti saranno informati delle modifiche significative
• L'uso continuato della piattaforma implica l'accettazione degli aggiornamenti`
          }
        ]
      },
      {
        title: "13. Informazioni di Contatto",
        subsections: [
          {
            title: "",
            content: `Per richieste relative alla privacy:

Email: info@bidcourt.it`
          }
        ]
      },
      {
        title: "14. Riconoscimento",
        subsections: [
          {
            title: "",
            content: "Utilizzando Bidcourt, riconosci di aver letto e compreso questa Informativa sulla Privacy."
          }
        ]
      }
    ],
    lastUpdated: "Ultimo Aggiornamento: 26/11/2024"
  }
};

const PrivacyPolicy: React.FC = () => {
  const { language } = useLanguage();
  const content = privacyContent[language as keyof typeof privacyContent];

  return (
    <div className="bg-gray-50 dark:bg-dark-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          {content.title}
        </h1>

        <div className="space-y-8 text-gray-700 dark:text-gray-300">
          {content.sections.map((section, index) => (
            <section key={index} className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className="mb-4">
                    {subsection.title && (
                      <h3 className="text-lg font-medium mb-2">
                        {subsection.title}
                      </h3>
                    )}
                    <div className="whitespace-pre-line text-base">
                      {subsection.content}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ))}

          <p className="text-sm text-gray-500 dark:text-gray-400 mt-8">
            {content.lastUpdated}
          </p>
        </div>
      </div>
    </div>
  );
};

export { PrivacyPolicy }; 