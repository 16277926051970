import { useState } from "react";
import { collection, addDoc, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db, functions } from "../lib/firebase";
import { useAuth } from "../context/AuthContext";
import { useNotification } from "../context/NotificationContext";
import { stripeService } from "../services/stripe.service";

interface PreAuthorizeBidResponse {
	clientSecret: string;
	paymentIntentId: string;
}

export const useBidding = (auctionId: string) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { user } = useAuth();
	const { showNotification } = useNotification();

	const submitBid = async (amount: number, paymentMethodId: string, onSuccess: () => void) => {
		if (!user) {
			throw new Error("Must be logged in to bid");
		}

		if (!auctionId) {
			throw new Error("Invalid auction ID");
		}

		setIsSubmitting(true);

		try {
			// Pre-authorize the payment
			const { paymentIntentId } = await stripeService.preAuthorizeBid({
				auctionId,
				bidAmount: amount,
				paymentMethodId,
			});

			// Create the bid document
			const bidRef = await addDoc(collection(db, "bids"), {
				auctionId,
				userId: user.id,
				amount,
				status: "active",
				paymentIntentId,
				createdAt: serverTimestamp(),
			});

			// Update the auction's current bid
			const auctionRef = doc(db, "auctions", auctionId);
			await updateDoc(auctionRef, {
				currentBid: amount,
				lastBidId: bidRef.id,
				lastBidAt: serverTimestamp(),
			});
			setIsSubmitting(false);
		
			onSuccess();

		} catch (error) {
			console.error("Error placing bid:", error);
			throw error;
		} finally {
			setIsSubmitting(false);
		}
	};

	return {
		submitBid,
		isSubmitting,
	};
};
