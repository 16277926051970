import React from 'react';
import { Globe } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

export const LanguageSwitcher: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <button
      onClick={() => setLanguage(language === 'en' ? 'it' : 'en')}
      className="p-2 rounded-full text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
      aria-label="Toggle language"
    >
      <div className="flex items-center space-x-1">
        <Globe className="h-5 w-5" />
        <span className="text-sm font-medium">{language.toUpperCase()}</span>
      </div>
    </button>
  );
};