import React, { useState } from 'react';
import { X, ChevronDown } from 'lucide-react';
import { createAuction } from '../../services/auction.service';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { addDays, format, isAfter } from 'date-fns';
import { useLanguage } from '../../context/LanguageContext';

interface Court {
  id: string;
  name: string;
  type: string;
}

interface AddAuctionModalProps {
  courts: Court[];
  onClose: () => void;
  onAdd: (auctionData: any) => void;
}

export const AddAuctionModal: React.FC<AddAuctionModalProps> = ({ courts, onClose, onAdd }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const { t } = useLanguage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [auctionType, setAuctionType] = useState<'single' | 'recurring'>('single');
  const [formData, setFormData] = useState({
    courtId: '',
    date: '',
    startTime: '',
    endTime: '',
    basePrice: '',
    // Recurring auction fields
    weekdays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    },
    untilDate: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) {
      showNotification('You must be logged in to create an auction', 'error');
      return;
    }

    setIsSubmitting(true);

    try {
      // Get the selected court data
      const selectedCourt = courts.find(court => court.id === formData.courtId);
      
      // Create base auction data with court's type2 (indoor/outdoor status)
      const baseAuctionData = {
        courtId: formData.courtId,
        venueId: user.id,
        date: formData.date,
        startTime: formData.startTime,
        endTime: formData.endTime,
        basePrice: Number(formData.basePrice),
        type2: selectedCourt?.type2 // Get type2 from the selected court
      };

      if (auctionType === 'single') {
        // For single auctions
        await createAuction(baseAuctionData);
        onAdd();
      } else {
        // For recurring auctions
        const selectedWeekdays = Object.entries(formData.weekdays)
          .filter(([_, selected]) => selected)
          .map(([day]) => day);

        if (selectedWeekdays.length === 0) {
          throw new Error('Please select at least one weekday');
        }

        const startDate = new Date(formData.date);
        const untilDate = new Date(formData.untilDate);

        if (!isAfter(untilDate, startDate)) {
          throw new Error('End date must be after start date');
        }

        const auctionPromises = [];
        let currentDate = startDate;

        while (currentDate <= untilDate) {
          const dayOfWeek = format(currentDate, 'EEEE').toLowerCase();
          if (formData.weekdays[dayOfWeek]) {
            const auctionData = {
              ...baseAuctionData,
              date: format(currentDate, 'yyyy-MM-dd'),
            };
            auctionPromises.push(createAuction(auctionData));
          }
          currentDate = addDays(currentDate, 1);
        }

        await Promise.all(auctionPromises);
      }

      showNotification('Auction(s) created successfully', 'success');
      onClose();
    } catch (error) {
      console.error('Error creating auction:', error);
      showNotification('Failed to create auction', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b border-dark-700">
          <h2 className="text-lg font-medium text-white">
            {t('auction.addNew')}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          {/* Auction Type */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300 mb-2">
              {t('auction.type')}
            </label>
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="auctionType"
                  value="single"
                  checked={auctionType === 'single'}
                  onChange={() => setAuctionType('single')}
                  className="mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 dark:border-gray-600"
                />
                <span className="text-gray-300">{t('auction.single')}</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="auctionType"
                  value="recurring"
                  checked={auctionType === 'recurring'}
                  onChange={() => setAuctionType('recurring')}
                  className="mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 dark:border-gray-600"
                />
                <span className="text-gray-300">{t('auction.recurring')}</span>
              </label>
            </div>
          </div>

          {/* Court Selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300 mb-2">
              {t('auction.selectCourt')}
            </label>
            <div className="mt-1 relative">
              <select
                required
                value={formData.courtId}
                onChange={e => setFormData(prev => ({ ...prev, courtId: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white appearance-none"
              >
                <option value="">Select a court</option>
                {courts.map(court => (
                  <option key={court.id} value={court.id}>
                    {court.name} - {court.type}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-3 h-5 w-5 text-gray-400" />
            </div>
          </div>

          {/* Date */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300 mb-2">
              {t('auction.date')}
            </label>
            <input
              type="date"
              required
              value={formData.date}
              onChange={e => setFormData(prev => ({ ...prev, date: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          {/* Times */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                {t('auction.startTime')}
              </label>
              <input
                type="time"
                required
                value={formData.startTime}
                onChange={e => setFormData(prev => ({ ...prev, startTime: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                {t('auction.endTime')}
              </label>
              <input
                type="time"
                required
                value={formData.endTime}
                onChange={e => setFormData(prev => ({ ...prev, endTime: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          {/* Base Price */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-300 mb-2">
              {t('auction.basePrice')}
            </label>
            <input
              type="number"
              required
              min="0"
              step="0.01"
              value={formData.basePrice}
              onChange={e => setFormData(prev => ({ ...prev, basePrice: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          {/* Recurring auction fields - show only when auctionType is 'recurring' */}
          {auctionType === 'recurring' && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {t('auction.weekdays')}
                </label>
                <div className="grid grid-cols-2 gap-2">
                  {Object.entries(formData.weekdays).map(([day, checked]) => (
                    <label key={day} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            weekdays: {
                              ...prev.weekdays,
                              [day]: e.target.checked,
                            },
                          }))
                        }
                        className="rounded border-gray-300 dark:border-dark-600"
                      />
                      <span className="text-sm text-gray-300 capitalize">{day}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {t('auction.untilDate')}
                </label>
                <input
                  type="date"
                  required={auctionType === 'recurring'}
                  value={formData.untilDate}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, untilDate: e.target.value }))
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 px-3 py-2 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
                />
              </div>
            </>
          )}

          {/* Buttons inside form */}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-600 rounded-md text-gray-300 hover:bg-gray-700"
              disabled={isSubmitting}
            >
              {t('auction.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : t('auction.create')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};