// src/hooks/useUsers.ts
import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, orderBy, limit, startAfter, getDocs, type Query, type QuerySnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: 'user' | 'vendor' | 'admin';
  status: 'active' | 'inactive' | 'suspended';
  createdAt: Date;
  lastActive: Date;
  emailVerified: boolean;
}

interface UsersResponse {
  users: User[];
  hasMore: boolean;
  loading: boolean;
  error: string | null;
  loadMore: () => Promise<void>;
  refresh: () => void;
}

const USERS_PER_PAGE = 10;

export const useUsers = (
  searchTerm: string = '',
  statusFilter: string = 'all'
): UsersResponse => {
  const [users, setUsers] = useState<User[]>([]);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const createQuery = (lastDocument?: any): Query => {
    let baseQuery = query(collection(db, 'users'));

    if (statusFilter !== 'all') {
      baseQuery = query(baseQuery, where('status', '==', statusFilter));
    }

    if (searchTerm) {
      baseQuery = query(
        baseQuery,
        where('searchTerms', 'array-contains', searchTerm.toLowerCase())
      );
    }

    baseQuery = query(baseQuery, orderBy('createdAt', 'desc'));

    if (lastDocument) {
      baseQuery = query(baseQuery, startAfter(lastDocument));
    }

    return query(baseQuery, limit(USERS_PER_PAGE));
  };

  const loadUsers = async (isRefresh: boolean = false) => {
    try {
      setLoading(true);
      const q = createQuery(isRefresh ? null : lastDoc);
      const snapshot = await getDocs(q);

      const newUsers = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        lastActive: doc.data().lastActive?.toDate()
      })) as User[];

      setUsers(isRefresh ? newUsers : [...users, ...newUsers]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === USERS_PER_PAGE);
      setError(null);
    } catch (err) {
      console.error('Error loading users:', err);
      setError(err instanceof Error ? err.message : 'Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers(true);
  }, [searchTerm, statusFilter]);

  const loadMore = async () => {
    if (!hasMore || loading) return;
    await loadUsers();
  };

  const refresh = () => {
    loadUsers(true);
  };

  return { users, hasMore, loading, error, loadMore, refresh };
};
