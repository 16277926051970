import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { addDays, subHours, parse } from 'date-fns';

export const createAuction = async (auctionData: {
  courtId: string;
  date: string;
  startTime: string;
  endTime: string;
  basePrice: number;
  venueId: string;
}) => {
  try {
    const courtDoc = await getDoc(doc(db, 'courts', auctionData.courtId));
    if (!courtDoc.exists()) {
      throw new Error('Court not found');
    }

    const courtData = courtDoc.data();
    console.log('Retrieved court data:', courtData);

    const eventStartDateTime = parse(
      `${auctionData.date} ${auctionData.startTime}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );
    const auctionEndTime = subHours(eventStartDateTime, 24);

    const auctionDocData = {
      ...auctionData,
      status: 'active',
      currentBid: auctionData.basePrice,
      participants: 0,
      createdAt: new Date().toISOString(),
      auctionEndTime: auctionEndTime.toISOString(),
      court: {
        type: courtData.type,
        type2: courtData.type2,
        name: courtData.name
      }
    };
    console.log('Creating auction with data:', auctionDocData);

    const auctionRef = await addDoc(collection(db, 'auctions'), auctionDocData);
    
    return {
      id: auctionRef.id,
      ...auctionDocData,
    };
  } catch (error) {
    console.error('Error creating auction:', error);
    throw error;
  }
};