import React, { useState } from 'react';
import { User, CreditCard, Settings, LogOut, X as CloseIcon, UserCircle } from 'lucide-react';
import { ProfileGeneral } from './ProfileGeneral';
import { ProfileBilling } from './ProfileBilling';
import { ProfileSettings } from './ProfileSettings';
import { useLanguage } from '../../context/LanguageContext';
import { useVenueData } from '../../hooks/useVenueData';
import { VendorPayoutSetup } from '../vendor/VendorPayoutSetup';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserBillingTab } from '../tabs/UserBillingTab';

interface ProfileMenuProps {
  onClose: () => void;
  onLogout: () => void;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ onClose, onLogout }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const { venueData } = useVenueData();
  const [activeTab, setActiveTab] = useState('Generale');
  const navigate = useNavigate();

  const handleLogout = async () => {
    onClose();
    onLogout();
  };

  // Define tabs based on user role
  const tabs = user?.role === 'vendor' ? [
    'Generale',
    'Fatturazione',
    'Impostazioni',
    'Legale'
  ] : [
    'Generale',
    'Metodi di Pagamento',
    'Impostazioni',
    'Legale'
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Generale':
        return <ProfileGeneral />;
      case 'Metodi di Pagamento':
        return user?.role === 'vendor' ? <VendorPayoutSetup /> : <UserBillingTab />;
      case 'Impostazioni':
        return <ProfileSettings />;
      case 'Legale':
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-4xl">
        <div className="flex justify-between items-center p-6 border-b dark:border-dark-700">
          <div className="flex items-center space-x-3">
            <UserCircle className="h-10 w-10" />
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {user?.role === 'vendor' ? venueData?.name || 'Default Venue' : user?.email || 'User'}
              </h3>
              <p className="text-sm text-gray-500">
                {user?.role === 'vendor' ? venueData?.email : user?.email}
              </p>
            </div>
          </div>
          <button onClick={onClose} className="text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400">
            <CloseIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="flex h-[600px]">
          {/* Sidebar */}
          <div className="w-64 border-r border-gray-200 dark:border-dark-700 p-6">
            <div className="space-y-1">
              <button
                onClick={() => setActiveTab('Generale')}
                className={`w-full flex items-center px-3 py-2 text-sm rounded-md ${
                  activeTab === 'Generale' 
                    ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400' 
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700'
                }`}
              >
                <User className="h-5 w-5 mr-2" />
                {t('nav.general')}
              </button>
              <button
                onClick={() => setActiveTab('Metodi di Pagamento')}
                className={`w-full flex items-center px-3 py-2 text-sm rounded-md ${
                  activeTab === 'Metodi di Pagamento' 
                    ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400' 
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700'
                }`}
              >
                <CreditCard className="h-5 w-5 mr-2" />
                {user?.role === 'vendor' ? t('nav.billing') : t('billing.paymentMethods')}
              </button>
              <button
                onClick={() => setActiveTab('Impostazioni')}
                className={`w-full flex items-center px-3 py-2 text-sm rounded-md ${
                  activeTab === 'Impostazioni' 
                    ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400' 
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700'
                }`}
              >
                <Settings className="h-5 w-5 mr-2" />
                {t('nav.settings')}
              </button>
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-3 py-2 text-sm rounded-md text-red-600 dark:text-red-500 hover:bg-red-50 dark:hover:bg-red-900/50"
              >
                <LogOut className="h-5 w-5 mr-2" />
                {t('nav.signOut')}
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="flex-1 p-6 overflow-y-auto bg-white dark:bg-dark-800">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};