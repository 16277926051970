import React, { useState } from 'react';
import { Building2, ExternalLink } from 'lucide-react';
import { useStripeConnect } from '../../hooks/useStripeConnect';
import { LoadingSpinner } from '../LoadingSpinner';
import { useLanguage } from '../../context/LanguageContext';
import { stripeService } from '../../services/stripe.service';

export const VendorPayoutSetup: React.FC = () => {
  const { t } = useLanguage();
  const { isConnected, isLoading } = useStripeConnect();
  const [loading, setLoading] = useState(false);

  const handleStripeConnect = async () => {
    try {
      setLoading(true);
      const {url} = await stripeService.createConnectAccount();
      window.location.href = url;
    } catch (error) {
      console.error('Failed to start Stripe onboarding:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleManagePayouts = async () => {
    try {
      setLoading(true);
      const { url } = await stripeService.createLoginLink();
      // open in new tab
      window.open(url, '_blank');
    } catch (error) {
      console.error('Failed to access Stripe dashboard:', error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          {t('vendor.payoutSettings')}
        </h2>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {t('vendor.payoutSettingsDescription')}
        </p>
      </div>

      <div className="bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <Building2 className="h-8 w-8 text-gray-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {isConnected ? t('vendor.stripeConnected') : t('vendor.noPayoutAccount')}
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {isConnected 
                  ? t('vendor.managePayoutDescription')
                  : t('vendor.connectStripeDescription')
                }
              </p>
            </div>
          </div>
          
          <button
            onClick={isConnected ? handleManagePayouts : handleStripeConnect}
            disabled={loading}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50"
          >
            {loading ? (
              <LoadingSpinner small />
            ) : (
              <>
                {isConnected ? t('vendor.managePayouts') : t('vendor.connectStripe')}
                <ExternalLink className="ml-2 h-4 w-4" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};