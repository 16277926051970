import React, { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { useNotification } from '../../context/NotificationContext';
import { useNavigate } from 'react-router-dom';

interface ChangePasswordPromptProps {
  onComplete: () => void;
}

export const ChangePasswordPrompt: React.FC<ChangePasswordPromptProps> = ({ onComplete }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      showNotification('Le password non coincidono', 'error');
      return;
    }

    if (newPassword.length < 8) {
      showNotification('La password deve essere di almeno 8 caratteri', 'error');
      return;
    }

    setIsSubmitting(true);
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('Utente non autenticato');
      
      await updatePassword(user, newPassword);
      
      // Update the user document
      await updateDoc(doc(db, 'users', user.uid), {
        hasChangedPassword: true
      });

      showNotification('Password aggiornata con successo', 'success');
      onComplete();
    } catch (error: any) {
      console.error('Error updating password:', error);
      showNotification('Errore durante l\'aggiornamento della password. Prova ad effettuare nuovamente il login.', 'error');
      // If we get a requires-recent-login error, sign out the user
      if (error.code === 'auth/requires-recent-login') {
        await auth.signOut();
        navigate('/login');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
          Imposta nuova password
        </h2>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
          Per motivi di sicurezza, è necessario impostare una nuova password al primo accesso.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Nuova Password
            </label>
            <input
              type="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              minLength={8}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Conferma Password
            </label>
            <input
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              minLength={8}
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md disabled:opacity-50"
          >
            {isSubmitting ? 'Aggiornamento...' : 'Aggiorna Password'}
          </button>
        </form>
      </div>
    </div>
  );
}; 