import React, { useState } from "react";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";
import { useLanguage } from "../../context/LanguageContext";
import { LoadingSpinner } from "../LoadingSpinner";
import { CreditCard, ExternalLink, Plus } from "lucide-react";
import { stripeService } from "../../services/stripe.service";

export const UserBillingTab: React.FC = () => {
	const { t } = useLanguage();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnDirectoBillingPage = async () => {
    try {
      setIsLoading(true);
			const { url } = await stripeService.createBillingPortalLink();
      window.open(url, '_blank');
		} catch (error) {
			console.error("Error adding payment method:", error);
    } finally {
      setIsLoading(false);
    }
	};

	return (
		<div className="space-y-6">
			<div>
				<h2 className="text-2xl font-bold text-gray-900 dark:text-white">{t("billing.paymentMethods")}</h2>
				<p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{t("billing.managePaymentMethods")}</p>
			</div>

			

				<button onClick={handleOnDirectoBillingPage} disabled={isLoading} className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
					{isLoading ? (
						<LoadingSpinner small />
					) : (
						<>
							{t("billing.managePaymentMethodsLink")}
							<ExternalLink className="ml-2 h-4 w-4" />
						</>
					)}
				</button>
		</div>
	);
};
