import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { X } from 'lucide-react';
import { validatePassword } from '../../utils/validation';
import { updatePassword } from 'firebase/auth';
import { auth } from '../../lib/firebase';

interface ChangePasswordModalProps {
  onClose: () => void;
  isFirstLogin?: boolean;
  onSuccess?: () => Promise<void>;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ 
  onClose,
  isFirstLogin = false,
  onSuccess
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showNotification } = useNotification();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      showNotification('Le password non coincidono', 'error');
      return;
    }

    const passwordErrors = validatePassword(newPassword);
    if (passwordErrors.length > 0) {
      showNotification(passwordErrors[0], 'error');
      return;
    }

    setIsSubmitting(true);

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('Utente non autenticato');

      await updatePassword(user, newPassword);
      showNotification('Password aggiornata con successo', 'success');
      
      if (onSuccess) {
        await onSuccess();
      }
      
      onClose();
    } catch (error) {
      console.error('Error updating password:', error);
      showNotification(
        'Errore durante l\'aggiornamento della password. Riprova.',
        'error'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg p-6 w-full max-w-md relative">
        {!isFirstLogin && (
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        )}

        <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          {isFirstLogin ? 'Imposta nuova password' : 'Cambia password'}
        </h2>

        {isFirstLogin && (
          <p className="mb-4 text-sm text-gray-600 dark:text-gray-400">
            Per ragioni di sicurezza, è necessario impostare una nuova password al primo accesso.
          </p>
        )}

        {/* Rest of the modal content remains the same */}
      </div>
    </div>
  );
}; 