import { useState } from 'react';
import { auth } from '../lib/firebase';
import { stripeService } from "../services/stripe.service";

interface CreateCheckoutSessionParams {
  auctionId: string;
  bidAmount: number;
  mode: 'setup';
  successUrl: string;
  cancelUrl: string;
  userEmail: string;
}

export const useStripeCheckout = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createCheckoutSession = async (params: CreateCheckoutSessionParams) => {
    setLoading(true);
    setError(null);

    try {
      return await stripeService.createCheckoutSession(params)

    

      
    } catch (err) {
      console.error('Detailed checkout error:', err);
      setError(err instanceof Error ? err.message : 'Failed to create checkout session');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { createCheckoutSession, loading, error };
}; 